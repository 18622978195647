import { Dispatch } from "react";
import {
  CLEAR_ADDUSER_DATA,
  CLEAR_BUSSUNIT_DATA,
  CLEAR_CURRENTUSER_DATA,
  CLEAR_DELUSER_DATA,
  CLEAR_EDITUSER_DATA,
  CLEAR_GETUSERDETAIL_DATA,
  CLEAR_LOADER_DATA,
  CLEAR_LOGIN_DATA,
  CLEAR_REPORTNAME_DATA,
  CLEAR_REPORTROOM_DATA,
  CLEAR_REPORT_DATA,
  CLEAR_ROOM_DATA,
  CLEAR_UNIQUE_LOGIN_USERS,
  CLEAR_USERDETAIL_DATA,
  CLEAR_VIEWUSERDETAIL_DATA,
  CLEAR_CHAT_DATA,
  CLEAR_ADDLOGO_DATA,
  CLEAR_TWILLIO_DATA,
  CLEAR_FEEDBACKIMAGES_DATA,
  CLEAR_GETTWILIODETAIL_DATA,
  CLEAR_VIEWFEEDS_DATA,
  CLEAR_TOPICS_DATA,
} from "./actionTypes";

export const clearAllReducerData = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CLEAR_ADDUSER_DATA,
    });
    dispatch({
      type: CLEAR_BUSSUNIT_DATA,
    });
    dispatch({
      type: CLEAR_DELUSER_DATA,
    });
    dispatch({
      type: CLEAR_EDITUSER_DATA,
    });
    dispatch({
      type: CLEAR_CURRENTUSER_DATA,
    });
    dispatch({
      type: CLEAR_REPORT_DATA,
    });
    dispatch({
      type: CLEAR_REPORTNAME_DATA,
    });
    dispatch({
      type: CLEAR_REPORTROOM_DATA,
    });
    dispatch({
      type: CLEAR_GETUSERDETAIL_DATA,
    });
    dispatch({
      type: CLEAR_LOADER_DATA,
    });
    dispatch({
      type: CLEAR_LOGIN_DATA,
    });
    dispatch({
      type: CLEAR_ROOM_DATA,
    });
    dispatch({
      type: CLEAR_UNIQUE_LOGIN_USERS,
    });
    dispatch({
      type: CLEAR_USERDETAIL_DATA,
    });
    dispatch({
      type: CLEAR_VIEWUSERDETAIL_DATA,
    });
    dispatch({
      type: CLEAR_CHAT_DATA,
    });
    dispatch({
      type: CLEAR_ADDLOGO_DATA,
    });
    dispatch({
      type: CLEAR_TWILLIO_DATA,
    });
    dispatch({
      type: CLEAR_GETTWILIODETAIL_DATA,
    });
    dispatch({
      type: CLEAR_FEEDBACKIMAGES_DATA,
    });
    dispatch({
      type: CLEAR_VIEWFEEDS_DATA,
    });
    dispatch({
      type: CLEAR_TOPICS_DATA,
    });
  };
};
