import { useState } from "react";
import "./Webrtcstats.css";
import Header from "../../components/Header";
import {
  getWebrtcStats,
  getFilteredStats,
  clearStats,
} from "../../services/getWebrtcStats";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { DateTimePicker } from "react-rainbow-components";
import { useDispatch } from "react-redux";
import { handleGetReportName } from "../../actions/getReportName";
import { handleGetReportRoom } from "../../actions/getReportRoom";
import LoadingSpinner from "../../components/Spinner";
import ModalComponent from "../../components/Modal";
import { CLOSE_MODAL, OPEN_MODAL } from "../../actions/actionTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  
  faSearch,
  faTrash,
  
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {timeZoneFormat} from '../../utils/dateformat'
import { toast } from "react-toastify";
import { reportAPI } from "../../utils/api";
import { FormControl,Autocomplete, TextField} from "@mui/material";

const Webrtcstats = () => {
  const [stats, setStats] = useState({});
  const [apiSucceed, setApiSucceed] = useState(false);
  const [name, setName] = useState({label: "", value: ""});
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [room, setRoom] = useState({label: "", value: ""});
  const [loaderActive, setloaderActive] = useState(false);
  const [deleteLog, setDeleteLog] = useState("");
  const [nameArray, setNameArray] = useState([]);
  const [roomArray, setRoomArray] = useState([]);

  const dispatch = useDispatch();

  function fetchNames() {
    getuser();
    getrooms();
    // dispatch(handleGetReportName());
    // dispatch(handleGetReportRoom());
  }

  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      let labeledUsers:any = [];
      // eslint-disable-next-line array-callback-return
      res.data.users.map((user: any) => {
        if (user && user.username) {
          // @ts-ignore
          labeledUsers.push({ label: user.username, value: user._id });
        }
      });
      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });

      labeledUsers.splice(0, 0, {label: "All Users", value: "All"})
      //@ts-ignore
      setNameArray(labeledUsers);
    });
  };

  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      let labeledUsers = [{label: "All Rooms", value: "All"}];
      // eslint-disable-next-line array-callback-return
      res.data.rooms.map((roomValue: any, index) => {
        if (roomValue) {
          // @ts-ignore
          labeledUsers.push({label: roomValue.roomName, value: roomValue._id});
        }
      });
      //@ts-ignore
      setRoomArray(labeledUsers);
    });
  };

  const fetchStats = async () => {
    setloaderActive(true);
    const responseData = await getWebrtcStats();
    if (responseData.success) {
      setStats(responseData);
      setApiSucceed(true);
      setloaderActive(false);
    } else {
      console.error(responseData);
      setloaderActive(false);
    }
  };

  const fetchFilteredStats = async (startdate, enddate, name, roomname) => {
    setloaderActive(true);
    const responseData = await getFilteredStats(
      formatDate(startdate),
      formatDate(enddate),
      name.value,
      roomname.value
    );
    if (responseData.success) {
      setStats(responseData);
      setApiSucceed(true);
      setloaderActive(false);
    } else {
      console.error(responseData);
      setloaderActive(false);
    }
  };

  const clearlogs = async (type) => {
    setloaderActive(true);
    const responseData: any = await clearStats(type);
    if (responseData.success) {
      resetFilters();
      setloaderActive(false);
      dispatch({
        type: CLOSE_MODAL,
      });
      toast.success("Successfully Deleted");
    } else {
      console.error(responseData);
      setloaderActive(false);
      dispatch({
        type: CLOSE_MODAL,
      });
      toast.error("Server Error Occured");
    }
  };

  useEffect(() => {
    // fetchStats();
    fetchNames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gettime = (date) => {
    // console.log({date})
    const {format} = timeZoneFormat()
    console.log({format})
    return moment(date).format(format);

  };

  const formatDate = (date) => {
    var d = new Date(date);
    return d.toUTCString();
  };

  const handleNameChange = (s: any) => {
    setName(s);
    if (s.value === "All") {
      setName({label: "", value: ""});
      // return fetchStats();
    }
    // return fetchFilteredStats(startdate, enddate, s.value, room);
  };

  const handleRoomChange = (r: any) => {
    setRoom(r);

    if (r.value === "All") {
      setRoom({label: "", value: ""});
      // return fetchStats();
    }
    // return fetchFilteredStats(startdate, enddate, name, r.value);
  };

  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };

  const resetFilters = () => {
    setName({label: "All", value: ""});
    setStartdate(new Date());
    setEnddate(new Date());
    setRoom({label: "All", value: ""});
    // fetchStats();
  };

  const handleDelete = (e: any, typeToDelete) => {
    e.preventDefault();
    clearlogs(typeToDelete);
  };

  const openDeleteModal = (type: string) => {
    setDeleteLog(type);
    dispatch({
      type: OPEN_MODAL,
      deleteLog,
    });
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    dispatch({
      type: CLOSE_MODAL,
    });
  };

  return (
    <>
      <Header />
      <LoadingSpinner active={loaderActive} />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">WebRTC Stats</span>
        <div className="datepicker-container">
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />
          <div className="text-center mt-4">
            <Button
              id="search"
              variant="info"
              onClick={() => fetchFilteredStats(startdate, enddate, name, room)}
            >
              <FontAwesomeIcon icon={faSearch} /> &nbsp; Search
            </Button>
          </div>
        </div>
      </nav>
      <div className="row">
          <div className="col">
          <div className="filters-container mt-4" style={{width: "100%"}}>
            <Button
              id="stats-clear"
              variant="danger"
              className="mr-1 webrtc-btn"
              onClick={() => openDeleteModal("stats")}
            >
              <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete WebRTC Stats
            </Button>
            <Button
              id="score-clear"
              variant="danger"
              className="mr-1 webrtc-btn"
              onClick={() => openDeleteModal("score")}
            >
              <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete Network Score
            </Button>
            <Button
              id="search"
              variant="info"
              className="mr-1 webrtc-btn"
              onClick={() => resetFilters()}
            >
              Reset Filters
            </Button>

                <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // name="username"
                          value={name}
                          options={nameArray}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All User" />
                          )}
                          onChange={(event, value) => handleNameChange(value)}
                        />
                  </FormControl>
                  <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // name="username"
                          value={room}
                          options={roomArray}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All Rooms" />
                          )}
                          onChange={(event, value) => handleRoomChange(value)}
                        />
                   </FormControl>
            {/* <Dropdown className="select-item">
              <Dropdown.Toggle
                variant="success"
                id="menu-dropdown-name"
                style={{ width: "90%" }}
              >
                {name}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  maxHeight: "400px",
                  width: "90%",
                  overflowY: "scroll",
                }}
              >
                {nameArray?.map((m) => (
                  <Dropdown.Item
                    onClick={() => {
                      handleNameChange(m);
                    }}
                  >
                    {m}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}

            {/* <Dropdown className="select-item">
              <Dropdown.Toggle
                variant="success"
                id="menu-dropdown-room"
                style={{ width: "90%" }}
              >
                {room ? room : "All Rooms"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  maxHeight: "400px",
                  overflowY: "scroll",
                  width: "90%",
                }}
              >
                <Dropdown.Item
                  onClick={() => {
                    handleRoomChange("All");
                  }}
                >
                  All
                </Dropdown.Item>
                {roomArray?.map((m) => (
                  <Dropdown.Item
                    onClick={() => {
                      handleRoomChange(m);
                    }}
                  >
                    {m}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <br />
          </div>
          </div>
      </div>
      <div className="row">
        <div className="col">
          
          <br />
          <br />
          <br />
          <br />
          <div className="tabs">
            <div className="tab">
              <input className="stats-input" type="checkbox" id="chck1" />
              <label className="tab-label" htmlFor="chck1">
                WebRTC issues
              </label>
              {apiSucceed &&
                //@ts-ignore
                stats.webrtcIssues.map(
                  ({ roomName, username, createdAt, issue }) => {
                    return (
                      <div className="tab-content-stats">
                        Room:{" "}
                        <span className="stats-error-green">{roomName}</span>{" "}
                        User:{" "}
                        <span className="stats-error-dark">{username}</span>{" "}
                        Date:
                        <span className="stats-error-date">
                          {" "}
                          {gettime(createdAt)}{" "}
                        </span>
                        {issue.map(
                          ({
                            //@ts-ignore
                            reason,
                            ssrc,
                            trackIdentifier,
                            type,
                            statsSample,
                          }) => {
                            return (
                              <>
                                <div className="stats-issue">
                                  Reason:{" "}
                                  <span className="stats-error-red">
                                    {reason}
                                  </span>{" "}
                                  ssrc: {ssrc} track Identifier:{" "}
                                  {trackIdentifier} Type: {type}
                                  <br />
                                  <span className="stats-error-red">
                                    {JSON.stringify(statsSample)}
                                  </span>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                )}
            </div>
            <div className="tab">
              <input className="stats-input" type="checkbox" id="chck2" />
              <label className="tab-label" htmlFor="chck2">
                Network Score
              </label>
              {apiSucceed &&
                //@ts-ignore
                stats.networkScore.map(
                  ({ roomName, username, createdAt, score }) => {
                    return (
                      <div className="tab-content-stats">
                        Room:{" "}
                        <span className="stats-error-green">{roomName}</span>{" "}
                        User:{" "}
                        <span className="stats-error-dark">{username}</span>{" "}
                        Date:{" "}
                        <span className="stats-error-date">
                          {" "}
                          {gettime(createdAt)}{" "}
                        </span>{" "}
                        <br />{" "}
                        <span className="stats-error-red">
                          {JSON.stringify(score)}
                        </span>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </div>
      <ModalComponent>
        <Modal.Header closeButton>
          <Modal.Title>
            Are You sure you want to Delete all Enteries ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <h6>Do you want to continue ?</h6>

            <button
              className="danger link mt-3"
              onClick={(e) => handleDelete(e, deleteLog)}
            >
              YES
            </button>
            <button
              className="link mt-3 margin-left-10"
              onClick={(e) => handleClose(e)}
            >
              NO
            </button>
          </form>
        </Modal.Body>
      </ModalComponent>
    </>
  );
};
export default Webrtcstats;
