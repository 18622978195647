import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { getRooms, createRoom, deleteRoom, editRoom } from "../services/rooms";
import {
  GET_ROOMS_SUCCESS,
  GET_ROOMS_FAILURE,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_FAILURE,
  DEL_ROOM_SUCCESS,
  DEL_ROOM_FAILURE,
  CLOSE_MODAL,
} from "../actions/actionTypes";
import { toast } from "react-toastify";
const setRoomDetails = (roomData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ROOMS_SUCCESS,
      payload: roomData,
    });
  };
};

const setRoomError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ROOMS_FAILURE,
      payload: errorMessage,
    });
  };
};

export const getRoomsAction = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const roomData = await getRooms();
      if (roomData) {
        dispatch(setRoomDetails(roomData));
      } else {
        dispatch(
          setRoomError("Could not fetch room details. Please try again")
        );
      }

      return roomData;
    } catch (error: any) {
      setRoomError(
        error?.message || "Could not fetch room details. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const createRoomSuccess = (createOrDel: string, roomName: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: createOrDel === "create" ? CREATE_ROOM_SUCCESS : DEL_ROOM_SUCCESS,
      payload: `${roomName}`,
    });
  };
};

const createRoomFailure = (createOrDel: string, errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: createOrDel === "create" ? CREATE_ROOM_FAILURE : DEL_ROOM_FAILURE,
      error: errorMessage,
    });
  };
};

const editRoomSuccess = (roomName: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "editRoom",
      payload: `${roomName}`,
    });
  };
};

const editRoomFailure = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "editRoom",
      error: errorMessage,
    });
  };
};

export const addRoom = (
  roomName: string,
  description: string,
  maxParticipants: string,
  bussUnit: string,
  roomTypeValue: string,
  audioAllowedInRoom: boolean,
  imageName: any,
  allowPrivateCallInSilentRoom: boolean
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const createRoomData = await createRoom(
        roomName,
        description,
        maxParticipants,
        bussUnit,
        roomTypeValue,
        audioAllowedInRoom,
        imageName,
        allowPrivateCallInSilentRoom
      );
      if (createRoomData?.success) {
        
        toast("Room created successfully")
        dispatch(createRoomSuccess("create", roomName));
      } else {
      
        toast.error("Room already exists. Please provide another Roomname.")
        dispatch(
          createRoomFailure("create", "Could not create room. Please try again")
        );
      }
      dispatch(getRoomsAction());
      dispatch({ type: CLOSE_MODAL });
      setLoading(false);
      return createRoomData;
    } catch (error: any) {
      
      toast.error(error.message)
      createRoomFailure(
        "create",
        error ? error?.message : "Could not create room. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const delRoom = (roomId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const deleteRoomData = await deleteRoom(roomId);
      if (deleteRoomData.success) {
        dispatch(
          createRoomSuccess("del", `${roomId} has been successfully deleted`)
        );
        toast(`successfully deleted`);
      } else if (!deleteRoomData.success) {
        dispatch(
          createRoomFailure("del", "Could not delete room. Please try again")
        );
        toast.error(deleteRoomData.message);
      }
      dispatch(getRoomsAction());
    } catch (error: any) {
      toast.error(error.response.data.message);
      createRoomFailure(
        "del",
        error ? error?.message : "Could not delete room. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateRoom = (
  roomId: string,
  description:string,
  maxParticipants: string,
  bussUnit: string,
  roomTypeValue: string,
  audioAllowedInRoom: boolean,
  imageName: any,
  oldImageUrl: string,
  allowPrivateCallInSilentRoom: boolean
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const editRoomData = await editRoom(
        roomId,
        description,
        maxParticipants,
        bussUnit,
        roomTypeValue,
        audioAllowedInRoom,
        imageName,
        oldImageUrl,
        allowPrivateCallInSilentRoom
      );
      if (editRoomData) {
        toast("Successfully Updated")
        dispatch(editRoomSuccess(roomId));
      } else {
        dispatch(
          editRoomFailure("Could not edit room. Please try again")
        );
      }
      dispatch(getRoomsAction());
      dispatch({ type: CLOSE_MODAL });
      setLoading(false);
      return editRoomData;
    } catch (error: any) {
      editRoomFailure(
        error ? error?.message : "Could not edit room. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

