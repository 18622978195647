import "./styles.css";
import PlusIcon from "../../assets/images/plus-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalComponent from "./../../components/Modal";
import { OPEN_MODAL } from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { addRoom } from "../../actions/roomActions";
import { RootState } from "../../redux";
import Switch from "react-switch";
import { toast } from "react-toastify";

const AddRoomCard = () => {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.userDetails.role);
  const loading = useSelector((state: RootState) => state.loading.isLoading);
  const bussUnitsList = useSelector(
    (state: RootState) => state.bussUnit.bussUnits
  );

  const openAddRoomModal = () => {
    dispatch({
      type: OPEN_MODAL,
    });
    resetForm();
  };

  const [roomName, setRoomName] = useState("");
  const [description, setDescription] = useState("");
  const [maxParticipants, setMaxParticipants] = useState("");
  const [info, setInfo] = useState(false);
  const [roominfo, setRoomInfo] = useState(false);
  const [descriptioninfo, setDescriptionInfo] = useState(false);
  const [descriptionifoSpace, setDescriptionInfoSpace] = useState(false);
  const [partinfo, setPartInfo] = useState(false);
  const [teaminfo, setTeamInfo] = useState(false);
  const [roomtypeinfo, setRoomTypeInfo] = useState(false);
  const [logoinfo, setLogoInfo] = useState(false);
  const [imageName, setImageName] = useState(null);
  const [bussUnit, setBussUnit] = useState("");
  const [roomType, setRoomType] = useState("");
  const [roomTypeData,] = useState([
    {
      label: "Supervisor Room", value: "supervisor"
    },
    {
      label: "Meeting Room", value: "meeting"
    }
  ]);

  const [audioAllowedInRoom, setAudioAllowedInRoom] = useState(true);
  const [allowPrivateCallInSilentRoom, setCallAllowedInRoom] = useState(true);

  // const [startDate, setStartDate] = useState("");
  // const [duration, setDuration] = useState("");

  const handleBussUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBussUnit(e.target.value);
  };

  const handleRoomType = (value: string) => {
    setRoomType(value);
  };

  const handleAddRoom = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;
    if (
      roomName.length > 20 ||
      maxParticipants === "" ||
      roomName === "" ||
      description.length > 50 || /^\s+$/.test(description) ||
      /\s/g.test(roomName) ||
      // imageName === null ||
      bussUnit === "" ||
      roomType === ""
    ) {

      if (roomName.length > 20 || roomName === "" || /\s/g.test(roomName)) { setRoomInfo(true); } else { setRoomInfo(false); }
      if (description.length > 50) { setDescriptionInfo(true); } else { setDescriptionInfo(false); }
      if (/^\s+$/.test(description)) { setDescriptionInfoSpace(true); } else { setDescriptionInfoSpace(false); }

      if (maxParticipants === "") { setPartInfo(true); } else { setPartInfo(false); }
      if (bussUnit === "") { setTeamInfo(true); } else { setTeamInfo(false); }
      if (roomType === "") { setRoomTypeInfo(true); } else { setRoomTypeInfo(false); }
      // if (imageName === null) { setLogoInfo(true); } else { setLogoInfo(false); }

      setInfo(true);

    } else {
      setInfo(false);
      let roomTypeValue = roomType.split(" ")[0].toLowerCase();
      console.log({ roomTypeValue })
      dispatch(
        addRoom(roomName, description, maxParticipants, bussUnit, roomTypeValue, audioAllowedInRoom, imageName, allowPrivateCallInSilentRoom)
      );
    }
    //dispatch(addRoom(roomName, maxParticipants));
  };

  const handleRoomNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomName(e.target.value);
  };
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleMaxParticipantsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxParticipants(e.target.value);
  };

  const handlePictureUrlChange = (e: any) => {
    let imageFile = e.target.files[0];
    const fileSizeKB = imageFile.size / 1024;

    if (fileSizeKB < 10 || fileSizeKB > 5000) {
      toast.error(`The file size should be between 10KB and 5MB. Selected file size is ${(fileSizeKB / 1024).toFixed(2)}MB.`);
      setImageName(null);
      console.log(imageName);
      console.log("File size out of range, image name cleared");
      return;
    } else if (imageFile.type === "image/jpeg" || imageFile.type === "image/png") {
      setInfo(false);
      setImageName(imageFile);
    } else {
      setImageName(null);
    }
    console.log(e.target.files[0]);
  };

  const handleAudioSwitch = (checked: boolean) => {
    setAudioAllowedInRoom(checked);
  }

  const handleCallSwitch = (checked: boolean) => {
    setCallAllowedInRoom(checked);
  }

  const resetForm = () => {
    setRoomName("");
    setMaxParticipants("");
    setInfo(false);
    setImageName(null);
    setBussUnit("");
    setAudioAllowedInRoom(true);
    setDescription("")
    setCallAllowedInRoom(true);
  }

  var bussUnitsDisplay = bussUnitsList.map((bussUnitObject: any) => (
    <option key={bussUnitObject._id} value={bussUnitObject._id}>
      {bussUnitObject.bussUnit}
    </option>
  ));

  let roomTypeDisplay = roomTypeData.map((roomTypeValue: any, index) => (
    <option key={index} value={roomTypeValue.value}>
      {roomTypeValue.label}
    </option>
  ));

  if (role === "Supervisor" || "Admin") {
    return (
      <Fragment>
        <li>
          <div className="box add cursor-pointer" onClick={openAddRoomModal}>
            <div className="center">
              <img src={PlusIcon} alt="plus" />
              <h4>Add New Room</h4>
            </div>
          </div>
        </li>
        <ModalComponent>
          <Modal.Header closeButton>
            <Modal.Title>Add New Room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddRoom}>
              <div className="roomname-container">
                <h6>Room Name</h6>
                <FontAwesomeIcon
                  onClick={() => {
                    setInfo(!info);
                  }}
                  icon={faInfoCircle}
                />
              </div>
              <div className="input-container">
                <input
                  type="text"
                  name="room-name"
                  placeholder="Room Name"
                  value={roomName}
                  onChange={handleRoomNameChange}
                // pattern="[^\s]+"
                />
                {info && roominfo && (
                  <span className="error-msg">
                    Room Name should not have space <br /> Room name can not
                    have more than 20 character
                  </span>
                )}
              </div>
              <h6>Description</h6>
              <div className="input-container">
                <input
                  type="text"
                  name="description"
                  placeholder="Description (Not Mandatory)"
                  value={description}
                  onChange={handleDescriptionChange}
                // pattern="[^\s]+"
                />
                {info && descriptioninfo && (
                  <span className="error-msg">
                    Description can not have more than 50 character
                  </span>
                )}
                {info && descriptionifoSpace && (
                  <span className="error-msg">
                    Description should not have space
                  </span>
                )}
              </div>
              <h6>Max. Number of Participants</h6>
              <div className="input-container">
                <input
                  type="number"
                  name="max-participants"
                  placeholder=""
                  value={maxParticipants}
                  onChange={handleMaxParticipantsChange}
                  min={5}
                  max={100}
                />
                {info && partinfo && (
                  <span className="error-msg">
                    Should have minimum one participant
                  </span>
                )}
              </div>

              <h6>Please select Business Unit</h6>
              <div className="input-container">
                <select id="team" onChange={handleBussUnit} value={bussUnit}>
                  <option value="" disabled>
                    Please Select Business Unit
                  </option>
                  {bussUnitsDisplay}
                </select>
                {
                  <span className="error-msg">
                    {bussUnitsList.length <= 0
                      ? "There are no Business units created. Please create a Business unit first"
                      : null}
                  </span>
                }
                {info && teaminfo && (
                  <span className="error-msg">
                    Business unit cannot be blank.
                  </span>
                )}
              </div>

              <h6>Please select Room Type</h6>
              <div className="input-container">
                <select
                  id="team"
                  onChange={(e: any) => handleRoomType(e.target.value)}
                  value={roomType}
                >
                  <option value="" disabled>
                    Please Select Room Type
                  </option>
                  {roomTypeDisplay}
                </select>

                {info && roomtypeinfo && (
                  <span className="error-msg">Room Type cannot be blank.</span>
                )}
              </div>

              <div className="input-container">
                <h6>Logo Icon</h6>
                <p>The file size should be between 10KB and 5MB. (Not Mandatory)</p>
                <input
                  type="file"
                  name="picture-Url"
                  placeholder=""
                  onChange={handlePictureUrlChange}
                />
                {info && logoinfo && (
                  <span className="error-msg">Logo file is Required</span>
                )}
              </div>
              <div className="input-container">
                <h6>Audio Allowed in Room</h6>
                <label style={{ float: "left" }}>
                  <Switch
                    onChange={handleAudioSwitch}
                    checked={audioAllowedInRoom}
                    className="react-switch"
                  /> &nbsp;&nbsp;
                  <span className={`toggle-label ${audioAllowedInRoom && "toggle-enabled"}`}>
                    {audioAllowedInRoom ? "Everyone Can Speak" : "Nobody Can Speak"}
                  </span>
                </label>
              </div>
              {!audioAllowedInRoom &&
                <div> <br /> <br />
                  <h6>Private Call in Silent Room</h6>
                  <label style={{ float: "left" }}>
                    <Switch
                      onChange={handleCallSwitch}
                      checked={allowPrivateCallInSilentRoom}
                      className="react-switch"
                    /> &nbsp;&nbsp;
                    <span className={`toggle-label ${allowPrivateCallInSilentRoom && "toggle-enabled"}`}>
                      {allowPrivateCallInSilentRoom ? "Private Call Allowed" : "Private Call not Allowed"}
                    </span>
                  </label>
                </div>
              }
              <div className="submit-btn">
                <button
                  className="link mt-3"
                  type="submit"
                  disabled={loading || bussUnitsList.length <= 0}
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </ModalComponent>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default AddRoomCard;
