import {
    GET_BUSS_SUCCESS,
    GET_BUSS_FAILURE,
    CREATE_BUSS_FAILURE,
    CREATE_BUSS_SUCCESS,
    DEL_BUSS_FAILURE,
    DEL_BUSS_SUCCESS,
    CLEAR_BUSSUNIT_DATA
  } from "../actions/actionTypes";
  
  
  interface IAction {
    type: string;
    payload: any;
  }
  
  const initialState = {
    bussUnits: [],
    successMessage: "",
    failureMessage: "",
    delSuccessMessage:"",
    delFailureMessage:"",
  };
  
  const bussUnitReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case GET_BUSS_SUCCESS: {
        return {
          ...state,
          bussUnits: action.payload,
        };
      }
      case GET_BUSS_FAILURE: {
        return {
          ...state,
          failureMessage: action.payload,
        };
      }
      case CREATE_BUSS_SUCCESS: {
        return {
          ...state,
          successMessage: "Created Business Units successfully",
        };
      }
  
      case CREATE_BUSS_FAILURE: {
        return {
          ...state,
          failureMessage: action.payload,
        };
      }
  
      case DEL_BUSS_SUCCESS: {
        return {
          ...state,
          delSuccessMessage: action.payload,
        };
      }
  
      case DEL_BUSS_FAILURE: {
        return {
          ...state,
          delFailureMessage: action.payload,
        };
      }
      case CLEAR_BUSSUNIT_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  


  
  export default bussUnitReducer;
  