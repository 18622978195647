import request, { authRequest } from "./index";
import { timeZoneFormat } from "../utils/dateformat/index";
import { getToken } from "../utils/Token";

const { format } = timeZoneFormat();

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""

  },
};
export const viewAllUsers = async () => {
  config["headers"]["Authorization"] = getToken()
  const userDetailsResponse = await authRequest.get("/v1/api/user/all",config);
  return userDetailsResponse.data;
};

export const listAllUsers = async () => {
  const body = {
    format,
  };
  config["headers"]["Authorization"] = getToken()

  const usersListResponse = await authRequest.get("/v1/api/user/export-list", config);
  return usersListResponse.data;
};
