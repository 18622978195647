import request from "./index";
import { reportRequest } from "./index";
import qs from "querystring";
import { getToken } from "../utils/Token";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": getToken()
  },
};

export const getReportData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string,
  pagenumber: number
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    pageNo: pagenumber,
  };

  const response = await reportRequest.post("/v1/api/graphical-report/fetch-report", requestBody, config);

  return response.data;
};

export const getUniqueLoginUsers = async (
  starttime: string,
  endtime: string
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
  };

  const response = await reportRequest.post("/v1/api/license/get", requestBody, config);

  return response.data;
};

export const getUsersByRoom = async (
  starttime: string,
  endtime: string,
  room: Array<string>
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    room: room,
  });

  const response = await request.post("/reportsroom", requestBody, config);

  return response.data;
};

export const getExportSessionData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const response = await reportRequest.post(
    "/v1/api/graphical-report/export-session-data",
    requestBody,
    config
  );

  return response.data;
};

export const getExportExpressionData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const response = await reportRequest.post(
    "/v1/api/graphical-report/export-expression-data",
    requestBody,
    config
  );

  return response.data;
};

export const getExportSummaryData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const response = await reportRequest.post(
    "/v1/api/graphical-report/export-summary-data",
    requestBody,
    config
  );

  return response.data;
};

export const getExportNotLoggedData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const response = await reportRequest.post(
    "/v1/api/graphical-report/export-not-logged-data",
    requestBody,
    config
  );

  return response.data;
};

export const getExportViolationData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const response = await reportRequest.post(
    "/v1/api/graphical-report/export-violation-data",
    requestBody,
    config
  );

  return response.data;
};

export const getUserLocationData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string,
  pagenumber: number
) => {

  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    pageNo: pagenumber,
  };

  const response = await reportRequest.post("/v1/api/userlocation/fetch-geo-location-report", requestBody, config);
  
  return response.data ;
};

