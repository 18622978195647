import {
  CLEAR_REPORTNAME_DATA,
    GET_REPORT_NAME_FAILURE,
    GET_REPORT_NAME_SUCCESS
  } from "../actions/actionTypes";
  
  const initialState = {
    isName: false,
    NameData: [],
    error: "",
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const getReportNameReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case GET_REPORT_NAME_SUCCESS: {
        return {
          ...state,
          isName: true,
          NameData: action.payload,
        };
      }
      case GET_REPORT_NAME_FAILURE: {
        return {
          ...state,
          isName: false,
          error: action.payload.error,
        };
      }
      case CLEAR_REPORTNAME_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default getReportNameReducer;
  