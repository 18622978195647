import axios from "axios";
import { getToken, getActivityChannelUrl } from "../utils/Token";
import { activityChannelRequest } from ".";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
  data: "",
};

export const getTopics = async () => {
  config["headers"]["Authorization"] = getToken();

  const topicsResponse = await activityChannelRequest.get(
    "/v1/api/topics/get",
    config
  );
  return topicsResponse.data;
};

export const addTopic = async (
  username: String,
  topic: String,
  description: String,
  bussunit: String,
  permissions: Object
) => {
  let body = {
    topic,
    description,
    bussId: bussunit,
    permissions,
  };
  config["headers"]["Authorization"] = getToken();
  // if(option === 'read'){
  //   body = {
  //     ...body,
  //     read:true,
  //     write:false
  //   }
  // }
  const topicsResponse = await activityChannelRequest.post(
    "/v1/api/topics/create",
    body,
    config
  );
  return topicsResponse.data;
};

export const editTopic = async (
  topicId: string,
  topic: string,
  description: string,
  client: string,
  bussunit: string,
  permissions: Object,
  bussId: string
) => {
  let body = {
    topicId,
    description,
    permissions,
    bussId,
  };
  config["headers"]["Authorization"] = getToken();

  const topicsResponse = await activityChannelRequest.put(
    "/v1/api/topics/update",
    body,
    config
  );
  return topicsResponse.data;
};

export const deleteTopic = async (topicId: String) => {
  let body = {
    topicId: topicId,
  };

  config["headers"]["Authorization"] = getToken();
  config.data = JSON.stringify(body);

  const topicsResponse = await activityChannelRequest.delete(
    "/v1/api/topics/delete",
    config
  );
  return topicsResponse.data;
};
