import React from "react";
import { Redirect, Route } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import Loader from "../Loader";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const isLoading = useSelector((state: RootState) => state.login.isLoading);

  return isLoading ? (
    <Loader />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn && !isLoading && !localStorage.getItem('isLoggedIn') ? (
          <Redirect to="/login" />
        ) : isLoggedIn  ? (
          <Component {...props} />
        ) : (
          <></>
        )
      }
    />
  );
};

export default PrivateRoute;