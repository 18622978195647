import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_ALL_ADMIN_SETTINGS_SUCCESS,
  GET_ALL_ADMIN_SETTINGS_FAILURE,
  SET_UPDATE_SETTINGS_SUCCESS,
  SET_UPDATE_SETTINGS_FAILURE,
  SET_ADMIN_SETTINGS_VALUE,
} from "./actionTypes";
import {
  AdminSettings,
  updateStatusCall,
  updateValStatusCall,
  updateEmailPerm,
  updateAlertPerm,
  updateViolationPerm,
  updateNonePerm
} from "../services/AdminSettings";

export const handleAdminSettings = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await AdminSettings();
      if (responseData.success) {
        dispatch(setSuccess(responseData));
      } else {
        dispatch(setAdminSettingsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setAdminSettingsError(error.message.message));
      } else {
        dispatch(setAdminSettingsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateValStatus = (variable: string, value: string, type: string) => {
  // console.log("variable =>"+variable+"status =>" +status);

  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await updateValStatusCall(variable, value, type);

      //console.log("RESPONSE DATA =>"+responseData.success);

      if (responseData.success) {
        dispatch(setUpdateSuccess(responseData));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setUpdateSettingsError(error.message.message));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateEmailPermission = (type:string,variable: string, email: boolean) => {
  // console.log("variable =>"+variable+"status =>" +status);

  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await updateEmailPerm(type,variable, email);

      //console.log("RESPONSE DATA =>"+responseData.success);

      if (responseData.success) {
        dispatch(setUpdateSuccess(responseData));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setUpdateSettingsError(error.message.message));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};


export const updateAlertPermission = (type:string,variable: string, alert: boolean) => {
  // console.log("variable =>"+variable+"status =>" +status);

  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await updateAlertPerm(type,variable, alert);

      //console.log("RESPONSE DATA =>"+responseData.success);

      if (responseData.success) {
        dispatch(setUpdateSuccess(responseData));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setUpdateSettingsError(error.message.message));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateViolationPermission = (type:string,variable: string, saveviolation: boolean) => {
  // console.log("variable =>"+variable+"status =>" +status);

  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await updateViolationPerm(type,variable, saveviolation);

      //console.log("RESPONSE DATA =>"+responseData.success);

      if (responseData.success) {
        dispatch(setUpdateSuccess(responseData));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setUpdateSettingsError(error.message.message));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};


export const updateNonePermission = (type:string,variable: string) => {
  // console.log("variable =>"+variable+"status =>" +status);

  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await updateNonePerm(type,variable);

      //console.log("RESPONSE DATA =>"+responseData.success);

      if (responseData.success) {
        dispatch(setUpdateSuccess(responseData));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setUpdateSettingsError(error.message.message));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};





export const updateStatus = (type:string,variable: string, status: boolean) => {
  // console.log("variable =>"+variable+"status =>" +status);

  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await updateStatusCall(type,variable, status);

      //console.log("RESPONSE DATA =>"+responseData.success);

      if (responseData.success) {
        dispatch(setUpdateSuccess(responseData));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setUpdateSettingsError(error.message.message));
      } else {
        dispatch(setUpdateSettingsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};




export const updateAdminSettingsValue = (variable: string, value: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_ADMIN_SETTINGS_VALUE, payload: { variable, value } });
  };
};

const setSuccess = (responseData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ALL_ADMIN_SETTINGS_SUCCESS,
      payload: responseData,
    });
  };
};

const setUpdateSuccess = (responseData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_UPDATE_SETTINGS_SUCCESS,
      payload: responseData,
    });
  };
};

const setUpdateSettingsError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_UPDATE_SETTINGS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

const setAdminSettingsError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ALL_ADMIN_SETTINGS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
