import "./styles.css";

interface IAvatarProps {
  text: string;
  bg?: string;
  image?: string;
  height?: string;
  width?: string;
  radius?: string;
  fontSize?: string;
  textColor?: string;
  border?: string;
  size?: string;
  position?: string;
  weight?: string;
  initial?: string;
  style?: any;
}

const Avatar = (props: IAvatarProps) => {
  const getInitials = (name: string) => {
    const firstLastUserNames = name.split(" ");
    const firstLastName = firstLastUserNames.filter((_, index) => index < 2)
    const initials = firstLastName.map((name) => name[0]);
    return initials.join("");
  };

  const {
    text = "",
    bg = "#26a3db",
    width = "100",
    height = "100",
    radius = "10px",
    fontSize = "12",
    textColor = "white",
    border = "",
    size = "large",
    position,
    style = {}
  } = props;

  return (
    <div
      className="pravatar"
      style={{
        color: `${textColor}`,
        backgroundColor: `${bg}`,
        width: `${width}`,
        height: `${height}`,
        borderRadius: `${radius}`,
        fontSize: `${fontSize}`,
        border: `${border}`,
        backgroundPosition: `${position}`,
        backgroundSize: `${size}`,
        ...style
        // fontWeight: `${weight}`,
      }}
    >
      <p className="centered">{getInitials(text)}</p>
    </div>
  );
};

export default Avatar;
