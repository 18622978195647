import { getToken } from "../utils/Token";
import { authRequest } from "./index";

// interface sendData {
//   username: string;
//   email: string;
//   password?: string;
//   role: string;
//   first_name: string;
//   last_name: string;
//   mfa: boolean;
//   appUpdate: boolean;
//   faceVerification: boolean;
//   enableDesktopApp: boolean;
//   loginlock: boolean;
//   // complex_password: boolean,
//   bussunit?: string;
//   roomId?: string;
//   mobile?: string;
//   groupRoomsId?: Array<string>;
// }

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization":""
  },
};

export const editUser = async (
  _id: string,
  username: string,
  email: string,
  password: string,
  role: string,
  firstName: string,
  lastName: string,
  mfa: boolean,
  loginLock: boolean,
  complexPassword: boolean,
  appUpdate: boolean,
  faceVerification: boolean,
  enableDesktopApp: boolean,
  roomId?: string,
  bussId?: string,
  mobile?: string,
  groupRoomsId?: Array<string>
) => {


  config["headers"]["Authorization"] = getToken();
  let data: any = {
    _id,
    email,
    role,
    firstName: firstName,
    lastName: lastName,
    mfa,
    loginLock: loginLock,
    appUpdate,
    "faceVerification.isFaceVerificationEnabled": faceVerification,
    enableDesktopApp,
    // complex_password: complexPassword
  };

  if (
    bussId !== "" &&
    ((groupRoomsId && groupRoomsId.length > 0) ||
      role === "Director" ||
      role === "Manager")
  ) {
    data = { ...data, bussId: bussId };
  } else if ((role === "Supervisor" || role === "Agent") && bussId && roomId) {
    data = { ...data, bussId: bussId, roomId: roomId };
  }

  if (groupRoomsId && groupRoomsId.length > 0 && role !== "Admin") {
    data = { ...data, groupRoomsId };
  }

  if (mfa) {
    data = { ...data, mobile: mobile };
  }

  if (password !== "") {
    data = { ...data, password: password };
  }

  const requestBody = { ...data };
  const response = await authRequest.put("/v1/api/user/update", requestBody, config);

  return response.data;
};
