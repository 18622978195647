import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_BUSS_FAILURE,
  GET_BUSS_SUCCESS,
  CREATE_BUSS_SUCCESS,
  CREATE_BUSS_FAILURE,
  DEL_BUSS_SUCCESS,
  DEL_BUSS_FAILURE,
} from "./actionTypes";
import {
  getBussUnits,
  createBussUnit,
  deleteBussUnit,
} from "../services/bussUnit";
import { toast } from "react-toastify";

const setBussDetails = (bussUnit: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_BUSS_SUCCESS,
      payload: bussUnit,
    });
  };
};

const setBussError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_BUSS_FAILURE,
      payload: errorMessage,
    });
  };
};

export const getBussUnitsAction = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    setBussError("");
    try {
      const bussUnitData = await getBussUnits();
      if (bussUnitData.success) {
        dispatch(setBussDetails(bussUnitData.bussunits));
      } else if (!bussUnitData.success) {
        dispatch(setBussError(bussUnitData.message));
      }

      return bussUnitData;
    } catch (error: any) {
      setBussError(
        error?.response.data.message ||
          "Could not fetch room details. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const createBussSuccess = (createOrDel: string, roomName: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: createOrDel === "create" ? CREATE_BUSS_SUCCESS : DEL_BUSS_SUCCESS,
      payload: `${roomName}`,
    });
  };
};

const createBussFailure = (createOrDel: string, errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: createOrDel === "create" ? CREATE_BUSS_FAILURE : DEL_BUSS_FAILURE,
      error: errorMessage,
    });
  };
};

export const addBussUnit = (bussUnit: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const createBussData = await createBussUnit(bussUnit);
      if (createBussData.success) {
        dispatch(createBussSuccess("create", createBussData.bussUnit));
        toast(createBussData.message)
      } else if (!createBussData.success) {
        dispatch(
          createBussFailure(
            "create",
            "Could not create Business unit. Please try again"
          )
        );
        toast.error(createBussData.message)
      }
      dispatch(getBussUnitsAction());
      return createBussData;
    } catch (error: any) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const delBussUnit = (bussunit: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const deleteBussData = await deleteBussUnit(bussunit);
      if (deleteBussData.success) {
        dispatch(
          createBussSuccess(
            "del",
            `${deleteBussData.message} has been successfully deleted`
          )
        );
          toast(deleteBussData.message)
      }else{
        toast.error(deleteBussData.message)
      }
      dispatch(getBussUnitsAction());
    } catch (error: any) {
      toast.error(error.response.data.message);
      createBussFailure(
        "del",
        error
          ? error?.message
          : "Could not delete Business unit. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};
