import { getToken } from "../utils/Token";
import { roomRequest } from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""

  },
};

export const getRooms = async () => {
  config["headers"]["Authorization"] = getToken()
  const userDetailsResponse = await roomRequest.get("/v1/api/room/get-all-rooms",config);
  return userDetailsResponse.data;
};
export const getRoomsByBussUnit = async (bussunit:string) => {
  const reqBody = {
    bussId : bussunit
  }
  config["headers"]["Authorization"] = getToken()
  const userDetailsResponse = await roomRequest.post("/v1/api/room/get-rooms-by-buss-unit", reqBody, config);
  return userDetailsResponse.data;
};

export const createRoom = async (
  roomName: string,
  description:string,
  maxParticipants: string,
  bussUnit: string,
  roomTypeValue: string,
  audioAllowedInRoom: boolean,
  imageName: any,
  allowPrivateCallInSilentRoom: boolean
) => {
  const config_img = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": ""
    },
  };

  config_img["headers"]["Authorization"] = getToken();
  const data = new FormData();
  data.append("roomName", roomName);
  data.append("description", description);
  data.append("maxParticipantCount", maxParticipants);
  data.append("file", imageName);
  data.append("bussId", bussUnit);
  data.append("roomType", roomTypeValue);
  data.append("audioAllowedInRoom", audioAllowedInRoom.toString());
  data.append("allowPrivateCallInSilentRoom", allowPrivateCallInSilentRoom.toString());

  const uploadResponse = await roomRequest.post("v1/api/room/create", data, config_img);
  return uploadResponse.data;
};

export const deleteRoom = async (roomId: string) => {
  const reqBody = {
    roomId: roomId,
  }

  console.log({reqBody})
  const uploadResponse = await roomRequest.post("v1/api/room/delete", reqBody, config);
  return uploadResponse.data;
};

export const getConnectionDetails = async (roomname: string) => {
  const reqBody = qs.stringify({
    roomname: roomname,
  });
  const connectionDetailsData = await roomRequest.post(
    "/getConnectionDetails",
    reqBody,
    config
  );
  return connectionDetailsData?.data?.connectionDetails;
};

export const editRoom = async (
  roomId: string,
  description:string,
  maxParticipants: string,
  bussUnit: string,
  roomTypeValue: string,
  audioAllowedInRoom: boolean,
  imageName: any,
  oldImageUrl: string,
  allowPrivateCallInSilentRoom: boolean
) => {
  const config_img = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  config_img["headers"]["Authorization"] = getToken();
  const data = new FormData();
  data.append("roomId", roomId)
  data.append("description", description)
  data.append("maxParticipantCount", maxParticipants);
  data.append("bussId", bussUnit);
  data.append("roomType", roomTypeValue);
  data.append("audioAllowedInRoom", audioAllowedInRoom.toString());
  data.append("file", imageName);
  data.append("oldImageUrl", oldImageUrl);
  data.append("allowPrivateCallInSilentRoom", allowPrivateCallInSilentRoom.toString());

  const uploadResponse = await roomRequest.post("/v1/api/room/edit", data, config_img);
  return uploadResponse.data;
};
