import React,{useState} from 'react'
import Header from '../../components/Header';
import { DateTimePicker } from "react-rainbow-components";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUniqueLoginUsersAction } from "../../actions/uniqueLoginUsersActions";
import { RootState } from "../../redux";
import "./styles.css";



const License = () => {
    const [startdate, setStartdate] = useState(new Date());
    const [enddate, setEnddate] = useState(new Date());
    const uniqueUsersCount = useSelector(
        (state: RootState) => state.uniqueLoginUsers.uniqueUsersLength
    );
    const allUniqueUsers = useSelector(
        (state: RootState) => state.uniqueLoginUsers.uniqueUsers
    );
    
    const dispatch = useDispatch();

    const formatDate = (date) => {
        // let hour;
        // let min;
        // let sec;
      
        // var d = new Date(date),
        //   month = "" + (d.getMonth() + 1),
        //   day = "" + d.getDate(),
        //   year = d.getFullYear();
        // hour = d.getHours();
        // min = d.getMinutes();
        // sec = d.getSeconds();
        // if (month.length < 2) month = "0" + month;
        // if (day.length < 2) day = "0" + day;
    
        // let a = [year, month, day].join("-");
        // a = a + " " + hour + ":" + min + ":" + sec;
        // return a;
        var d = new Date(date)
        return d.toUTCString();
      };
    


  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };


  const DoSearch = () => {
      dispatch(
        getUniqueLoginUsersAction(
          formatDate(startdate),
          formatDate(enddate)
        )
      );
  };



    return (
        <>
        <Header />
        <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">License</span>

        <div className="datepicker-container">
      
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
            // format="yyyy-MM-dd HH:mm:ss"
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />
          <div className="text-center mt-4">
            <Button
              id="search"
              variant="info"
              onClick={() => DoSearch()}
            >
              Search
            </Button>
          </div>
        </div>
      </nav>
      <div className="container-fluid hw" id="main">
        <div className="row">
          <div className="report-container">
            <div className="header">
              <h1 className="session-chart">Total users {uniqueUsersCount}</h1>
              <div className="disp-flex-left"></div>
            </div>
            </div>
            </div>
            {
              allUniqueUsers.length > 0 ?
              <React.Fragment>
              <div className="row all_users">
              <div className="report-container">
              <div className="single_user_index"><h5>Sr. No.</h5></div>
              <div className="single_user_name"><h5>Name</h5></div>
              <div className="single_user_name"><h5>Username</h5></div>
              </div>
              </div>
            
              <span className="line"></span>
              </React.Fragment>
              : null
            }
            
            {allUniqueUsers.length > 0 ?
            allUniqueUsers.map((value,index) =>
            (
                <React.Fragment key={index}>
            <div className="row all_users">
            <div className="report-container">
            <div className="single_user_index"><h5>{index+1}</h5></div>
            <div className="single_user_name"><h5>{value.firstName} {value.lastName}</h5></div>
            <div className="single_user_name"><h5>{value.username}</h5></div>
            </div>
            </div>
           
            <span className="line"></span>
            </React.Fragment>
            )
            )
            
            :
            null
}
            </div>
    </>
    )
}

export default License;
