import {
  SET_ALL_GROUP_ROOM_DATA,
  SET_LOADER_FOR_GROUP_ROOM,
  CLEAR_GROUP_ROOM_DATA,
} from "../actions/actionTypes";

const initialState = {
  loader: false,
  groupRoomList: [],
};
interface IAction {
  type: string;
  payload: any;
}

const groupRoomReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_ALL_GROUP_ROOM_DATA: {
      return {
        ...state,
        groupRoomList: action.payload.allGroupRoom,
      };
    }
    case SET_LOADER_FOR_GROUP_ROOM: {
      return {
        ...state,
        loader: action.payload.loader,
      };
    }

    case CLEAR_GROUP_ROOM_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default groupRoomReducer;
