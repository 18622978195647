import { authRequest } from ".";
import { getToken } from "../utils/Token";

const config = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": ""
    },
};

export const getFeedbackImages = async () => {
    config["headers"]["Authorization"] = getToken()
    const feedbackImagesResponse = await authRequest.get("/v1/api/feedback/get", config);
    return feedbackImagesResponse.data;
};