import AppRouter from "./AppRouter";
import "./App.css";
import { isSessionActive } from "../services/login";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

require("dotenv").config();
function App() {
  useEffect(() => {
    let sessionActiveInterval = setInterval(async () => {
      await isSessionActive();
    }, 1000 * 60 * 10);

    return () => {
      clearTimeout(sessionActiveInterval);
    };
  }, []);
  return (
    <div className="App">
      <Router>
        <AppRouter />
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
