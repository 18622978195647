import { useEffect, useState } from "react";
import "./AddLogo.css";
import Header from "./../../components/Header";
import Logo2 from "../../assets/images/logo-2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { Alert, Spinner } from "react-bootstrap";
import React from "react";
import { getEnvironmentsAction } from "../../actions/environmentActions";
import { addLogoAction } from "../../actions/addLogo";
import { LOGO_PRESENT_STATUS } from "../../actions/actionTypes";

const AddLogo = () => {
    const dispatch = useDispatch();
    const isLogoUpdated = useSelector((state: RootState) => state.addLogoReducer.isLogoUpdated);
    const [logoName, setLogoName] = useState<File | null>(null);
    const [logoSizeError, setLogoSizeError] = useState("");
    const [logoDimensionError, setLogoDimensionError] = useState("");
    const [loader, setLoader] = useState(false);
    const [fileFormatError, setFileFormatError] = useState("");
    const [previewLogo, setPreviewLogo] = useState<string | null>(null); // New state for image preview
    const enviornments = useSelector(
        (state: RootState) => state.environmentsReducers.environments
    );
    let logoUrl = enviornments.APP_LOGO?.value;
    const [logo, setLogo] = useState(logoUrl);


    if(isLogoUpdated) {
        console.log("logo updated");
    }

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            setLogoName(file);
            setFileFormatError("");
            setLogoSizeError("");
            setLogoDimensionError("");
            setPreviewLogo(URL.createObjectURL(file)); // Set the image preview
            const logoSize = Math.floor(file.size / 1000000);
            const supportedFormats = ["png", "jpg", "jpeg", "svg", "PNG", "JPG", "JPEG", "SVG"];
            const fileFormat = file.type.split("/")[1];

            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const logoHeight = img.height;
                const logoWidth = img.width;
                if (logoHeight > 150 || logoWidth > 300) {
                    setLogoDimensionError(
                        `Please upload logo not greater than recommended size 150 X 300, selected logo is of size ${logoHeight} X ${logoWidth}`
                    );
                    setPreviewLogo(null);
                    setLogoName(null);
                }
            };

            if (logoSize > 2) {
                setLogoSizeError(`You are allowed to upload logo up to 2MB, selected logo is ${logoSize}MB`);
                setPreviewLogo(null);
                setLogoName(null);
            }

            if (!supportedFormats.includes(fileFormat)) {
                setFileFormatError("Please select allowed format type: png, jpg, jpeg, svg");
                setPreviewLogo(null);
                setLogoName(null);
            }
        }
    };
    const uploadLogo = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(logoName && !logoSizeError && !logoDimensionError && !fileFormatError) {
            dispatch(addLogoAction(logoName, setLoader));
            dispatch({ type:LOGO_PRESENT_STATUS  })
        }
    }

    useEffect(() => {
        dispatch(getEnvironmentsAction())
    },[dispatch, isLogoUpdated])

    useEffect(() => {
        dispatch({ type:LOGO_PRESENT_STATUS  })
    },[dispatch]);

    useEffect(() => {
        setLogo(logoUrl);
    },[logoUrl]);

    return (
        <>
            <Header />
            <section className="add_user">

                <div className="container">
                    <div className="row">
                        <div className="col-md-10">
                            <form className="add_form" onSubmit={uploadLogo}>
                                <h1>
                                    <span>Add Logo</span>
                                </h1>
                                {fileFormatError && <Alert variant="danger">{fileFormatError}</Alert>}
                                {logoSizeError && <Alert variant="danger">{logoSizeError}</Alert>}
                                {logoDimensionError && <Alert variant="danger">{logoDimensionError}</Alert>}
                                {isLogoUpdated && <Alert variant="success">Logo updated successfully</Alert>}
                                <p>LOGO * (recommended 150 X 300)</p>
                                <input
                                    accept="image/png, image/jpg, image/jpeg, image/svg"
                                    type="file"
                                    name="logo"
                                    placeholder="logo file"
                                    onChange={handleLogoChange}
                                />

                                <p>&nbsp;</p>

                                <button
                                    className="link marg-up-10"
                                    value="register"
                                    disabled={logoName == null}
                                >
                                    Add Logo {loader ?<Spinner animation={"border"} size="sm"></Spinner> : ""}
                                </button>
                            </form>
                        </div>
                        <div className="col-md-2 mt-5">
                            {previewLogo ? (
                                <img src={previewLogo} alt="preview logo" />
                            ) : logoUrl ? (
                                <img src={logo} alt="customer logo" onError={() => {
                                    setLogo(Logo2);
                                }} />
                            ) : (
                                <img src={Logo2} alt="default logo" />
                            )}

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AddLogo;
