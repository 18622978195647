import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { TWILLIO_SETTING_SUCCESS, TWILLIO_SETTING_FAILURE } from "./actionTypes";
import { saveTwilioSetting } from "../services/twilioSetting";
import { toast } from "react-toastify";


export const handleTwillioSetting = (
  AccountSid: string,
  AuthToken: string,
  date:string,  
  mobile?:string,
  mobileotpmessage?:string,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (AccountSid && AuthToken ) {
        const responseData = await saveTwilioSetting(
          AccountSid,
          AuthToken,         
          date,         
          mobile,
          mobileotpmessage,
        );
        if (responseData.success) {
          dispatch(setTwillioSettingError(""));
          dispatch(setSuccess());
          toast(responseData.message);
        }
        return responseData;
      }
    } catch (error: any) {
      if (AccountSid && AuthToken) {
        if (
          error.response.data.message &&
          error.response.data.message.message
        ) {
          dispatch(setTwillioSettingError(error.response.data.message.message));
        } else {
          dispatch(setTwillioSettingError("Incorrect Fields"));
        }
      } else {
        dispatch(setTwillioSettingError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setSuccess = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: TWILLIO_SETTING_SUCCESS,
    });
  };
};

const setTwillioSettingError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: TWILLIO_SETTING_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
