
import Avatar from "../NameAvatar";
import { IProfileProps } from "./interface";
//import ManJpg from "../../assets/images/man.jpg";


import "./styles.css";

const Profile = (props: IProfileProps) => {
  const { name = "", role = "" } = props;
  return (

    <div className="profile">
      <div className="text1">
        <p className="para_text">{name}({props.custCode})</p>
        <p className="para_text">{role}</p>
      </div>
    </div>

  );
};

export default Profile;
