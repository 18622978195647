import { getToken } from "../utils/Token";
import { utilRequest } from "./index";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""
  },
};

export const getBussUnits = async () => {
  config["headers"]["Authorization"] = getToken();
  const businessUnitsResponse = await utilRequest.get("/v1/api/bussunit/get",config);
  return businessUnitsResponse.data;
};

export const createBussUnit = async (bussunit: string) => {
  const reqBody ={
    bussUnit:bussunit,
  };
  config["headers"]["Authorization"] = getToken();
  const createBussUnitResponse = await utilRequest.post(
    "/v1/api/bussunit/create",
    reqBody,
    config
  );
  return createBussUnitResponse.data;
};

export const deleteBussUnit = async (bussunit: string) => {
  const reqBody = {
    bussId:bussunit
  };
  config["headers"]["Authorization"] = getToken();
  const delBussUnitResponse = await utilRequest.post(
    "/v1/api/bussunit/delete",
    reqBody,
    config
  );
  return delBussUnitResponse.data;
};
