import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_ALL_FEEDS_SUCCESS,
  GET_ALL_FEEDS_FAILURE,
  EDIT_FEED_SUCCESS,
  EDIT_FEED_FAILURE,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAILURE,
} from "./actionTypes";
import { getFeeds, deleteFeed, editFeed } from "../services/feeds";
import { toast } from "react-toastify";

export const handleViewAllFeeds = (topicId: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getFeeds(topicId);
      if (responseData.success) {
        dispatch(setSuccess(responseData.feeds));
      } else {
        dispatch(setViewAllFeedsError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setViewAllFeedsError(error.message.message));
      } else {
        dispatch(setViewAllFeedsError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setSuccess = (responseData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ALL_FEEDS_SUCCESS,
      payload: { feeds: responseData },
    });
  };
};

const setViewAllFeedsError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ALL_FEEDS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

export const handleDeleteFeed = (feedId: string, topicId: String) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (feedId) {
        const responseData = await deleteFeed(feedId);
        if (responseData.success) {
          dispatch(setDeleteSuccess(responseData.message, feedId));
        } else {
          toast(responseData.message);
          dispatch(setDeleteFeedError("Incorrect Fields"));
        }
        return responseData;
      }
    } catch (error: any) {
      if (feedId) {
        if (error.message && error.message.message) {
          dispatch(setDeleteFeedError(error.message.message));
        } else {
          dispatch(setDeleteFeedError("Incorrect Fields"));
        }
      } else {
        dispatch(setDeleteFeedError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setDeleteSuccess = (message: string, feedId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: DELETE_FEED_SUCCESS,
      payload: {
        message,
        feedId,
      },
    });
  };
};

const setDeleteFeedError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: DELETE_FEED_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

export const editFeedAction = (
  feedId: string,
  topicId: string,
  title: string,
  description: string,
  feedType: string
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (feedId) {
        const responseData = await editFeed(
          feedId,
          topicId,
          title,
          description,
          feedType
        );
        if (responseData.success) {
          dispatch(
            editFeedSuccess(responseData.message, feedId, title, description,feedType)
          );
          toast(responseData.message);
        } else {
          dispatch(editFeedFailure("Incorrect Fields"));
        }
        return responseData;
      }
    } catch (error: any) {
      if (feedId) {
        if (error.message && error.message.message) {
          dispatch(editFeedFailure(error.message.message));
        } else {
          dispatch(editFeedFailure("Incorrect Fields"));
        }
      } else {
        dispatch(editFeedFailure(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const editFeedSuccess = (message: string,feedId:string,title:string,description:string,type:string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: EDIT_FEED_SUCCESS,
      payload: {
        message,
        feedId,
        title,
        description,
        type
      },
    });
  };
};

const editFeedFailure = (message: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: EDIT_FEED_FAILURE,
      payload: {
        message,
      },
    });
  };
};
