import {
  GET_SPECIFIC_USER_DETAILS_SUCCESS,
  GET_SPECIFIC_USER_DETAILS_FAILURE,
  CLEAR_GETUSERDETAIL_DATA,
  SET_MFA,
} from "../actions/actionTypes";

const initialState = {
  id: "",
  userName: "",
  firstName: "",
  lastName: "",
  role: "",
  mfa: true,
  complexPassword: false,
  team: "",
  bussId: "",
  mobile: "",
  loginLock: false,
  errorMessage: "",
  appUpdate: false,
  enableDesktopApp: false,
  email: "",
  faceVerification: false,
  grouprooms: [],
};
interface IAction {
  type: string;
  payload: any;
}

const getUserDetail = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_SPECIFIC_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        id: action.payload.user._id,
        userName: action.payload.user.username,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        role: action.payload.user.role,
        mfa: action.payload.user.mfa,
        complexPassword: action.payload.user?.complexPassword,
        team: action.payload.user.roomId,
        bussId: action.payload.user.bussId,
        mobile: action.payload.user.mobile,
        loginLock: action.payload.user.loginLock,
        appUpdate: action.payload.user.appUpdate,
        enableDesktopApp: action.payload.user.enableDesktopApp,
        email: action.payload.user.email,
        faceVerification:
          action.payload.user.faceVerification.isFaceVerificationEnabled,
          grouprooms:action.payload.user.groupRoomsId
      };
    }
    case SET_MFA: {
      return {
        ...state,
        mfa: action.payload.mfa,
      };
    }

    case GET_SPECIFIC_USER_DETAILS_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload?.errorMessage,
      };
    }
    case CLEAR_GETUSERDETAIL_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default getUserDetail;
