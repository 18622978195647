import {
    GET_TWILIO_SETTING_DETAILS_SUCCESS,
    GET_TWILIO_SETTING_DETAILS_FAILURE,
    CLEAR_GETTWILIODETAIL_DATA,
  } from "../actions/actionTypes";
  
  const initialState = {
    AccountSid: "",
    AuthToken: "",
    mobile: "",
    mobileotpmessage:"",
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const getTwilioDetail = (state = initialState, action: IAction) => {
    switch (action.type) {
     case GET_TWILIO_SETTING_DETAILS_SUCCESS: {
      return {
          ...state,
          AccountSid: action.payload.twilio.accountSid,
          AuthToken: action.payload.twilio.authToken,
          mobile: action.payload.twilio.mobile,   
          mobileotpmessage: action.payload.twilio.otpMessage,       
        };
      }
      case GET_TWILIO_SETTING_DETAILS_FAILURE: {
        return {
          ...state,
          errorMessage: action.payload?.errorMessage,
        };
      }
      case CLEAR_GETTWILIODETAIL_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default getTwilioDetail;
  