import { getToken } from "../utils/Token";
import { authRequest } from "./index";

const config = {
  headers: {
    "content-type": "application/json",
    "Authorization": ``,
  },
};
export const getUserDetails = async () => {
  config["headers"]["Authorization"] = getToken();
  const userDetailsResponse = await authRequest.get("/v1/api/user/get",config);
  return userDetailsResponse.data;
};
