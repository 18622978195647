import {
    GET_REPORT_ROOM_FAILURE,
    GET_REPORT_ROOM_SUCCESS,
    GET_HOURLY_ROOM_FAILURE,
    GET_HOURLY_ROOM_SUCCESS,
    CLEAR_REPORTROOM_DATA
  } from "../actions/actionTypes";
  
  const initialState = {
    isRoom: false,
    isHourlyRoom:false,
    RoomData: [],
    HourlyRoom:[],
    error: "",

  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const getReportRoomReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case GET_REPORT_ROOM_SUCCESS: {
        return {
          ...state,
          isRoom: true,
          RoomData: action.payload,
        };
      }
      case GET_REPORT_ROOM_FAILURE: {
        return {
          ...state,
          isRoom: false,
          error: action.payload.error,
        };
      }
      case GET_HOURLY_ROOM_SUCCESS:{
        return {
          ...state,
          isHourlyRoom: true,
          HourlyRoom: action.payload,
        };
      }

      case GET_HOURLY_ROOM_FAILURE:{
        return {
          ...state,
          isHourlyRoom: true,
          error: action.payload.error,
        };
      }
      case CLEAR_REPORTROOM_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default getReportRoomReducer;
  