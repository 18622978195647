import { Dispatch } from "react";
import {
  GET_ALL_BACKGROUND_iMAGE,
  SET_DELETE_BACKGROUND_iMAGE_NAME,
  SET_BACKGROUND_IMAGE_LOADER,
} from "./actionTypes";
import { getAllBackgroundImage } from "../services/backgroundImage";
import { toast } from "react-toastify";

const setBussDetails = (backgroundImage: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ALL_BACKGROUND_iMAGE,
      payload: backgroundImage,
    });
  };
};

const setBussError = (errorMessage: string) => {
  toast(errorMessage);
};

export const getAllBackgroundImageActions = (company: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const backgroundImageData = await getAllBackgroundImage(company);
      if (backgroundImageData.success) {
        dispatch(setBussDetails(backgroundImageData.backgroundImage));
      } else if (!backgroundImageData.success) {
        setBussError(backgroundImageData.message.message);
      }

      return backgroundImageData;
    } catch (error: any) {
      setBussError(
        error?.response.data.message ||
          "Could not fetch room details. Please try again"
      );
    }
  };
};

export const setDeleteBackgroundImgName = (imageName: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_DELETE_BACKGROUND_iMAGE_NAME,
      payload: imageName,
    });
  };
};

export const setLoaderForBackgroundImage = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_BACKGROUND_IMAGE_LOADER,
    });
  };
};
