import axios from "../axiosConfig";
import { reportRequest, authRequest, roomRequest, utilRequest } from "../../../services";
import { getToken } from "../../Token";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": getToken()
  },
};

class Routes {
  gettAll() {
    config['headers']['Authorization'] = getToken();
    return authRequest.get("/v1/api/user/all", config );
  }
  gettRooms() {
    config['headers']['Authorization'] = getToken();
    return roomRequest.get("/v1/api/room/get-all-rooms", config);
  }

  getBussUnits() {
    config['headers']['Authorization'] = getToken();
    return utilRequest.get("/v1/api/bussunit/get-all", config);
  }

  getSummary({ dateFrom, dateTo, username, roomname, bussunit }) {
    config['headers']['Authorization'] = getToken();
    return reportRequest.post("/v1/api/drill-down-report", {
      dateFrom,
      dateTo,
      userId: username,
      roomId: roomname,
      bussId: bussunit,
    }, config);
  }

 getPrivateCallReport = async ({ dateFrom, dateTo, username, roomname }) => {
    config['headers']['Authorization'] = getToken();
    const requestBody = {
        dateFrom,
        dateTo,
        userId: username,
        roomId: roomname,
    }
    return reportRequest.post("/v1/api/fetch-privatecall-report",requestBody, config );
}

  getSummaryByUser({ dateFrom, dateTo, selectedUser, roomname, bussunit }) {
    config['headers']['Authorization'] = getToken();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return reportRequest.post("/v1/api/drill-down-report-by-user", {
      dateFrom,
      dateTo,
      userId: selectedUser,
      roomId: roomname,
      timezone,
      bussId: bussunit
    }, config);
  }

  getSummaryByDay({
    dateStart,
    dateEnd,
    selectedUser,
    selectedDay,
    roomname,
    bussunit,
  }) {
    config['headers']['Authorization'] = getToken();
    let dateFrom = new Date(selectedDay + "T00:00:00");
    let dateTo = new Date(selectedDay + "T23:59:59");
    return reportRequest.post("/v1/api/drill-down-report-by-day", {
      dateFrom,
      dateTo,
      userId: selectedUser,
      selectedDay,
      roomId: roomname,
      bussId: bussunit,
    }, config);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Routes();
