import axios from "axios";
const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});
export const mediaRequest = axios.create({
  baseURL: process.env.REACT_APP_MEDIA_URL,
  withCredentials: true,
});

// Room
export const roomRequest = axios.create({
  baseURL: process.env.REACT_APP_ROOM_URL,
  withCredentials: true,
});

// Utils
export const utilRequest = axios.create({
  baseURL: process.env.REACT_APP_UTIL_URL,
  withCredentials: true,
});

// Activity Channel
export const activityChannelRequest = axios.create({
  baseURL: process.env.REACT_APP_ACTIVITY_CHANNEL_URL,
  withCredentials: true,
});

// Chat
export const requestChat = axios.create({
  baseURL: process.env.REACT_APP_CHAT_URL,
  withCredentials: true,
});

// Whiteboard
export const requestWhiteboard = axios.create({
  baseURL: process.env.REACT_APP_WHITEBOARD_URL,
  withCredentials: true,
});

// Auth
export const authRequest = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  withCredentials: true,
});

// Report
export const reportRequest = axios.create({
  baseURL: process.env.REACT_APP_REPORT_URL,
  withCredentials: true,
});

// Desktop
export const deskRequest = axios.create({
  baseURL: process.env.REACT_APP_DESKTOP_APP_URL,
  withCredentials: true,
});

export default request;
