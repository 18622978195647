import { OPEN_MODAL, CLOSE_MODAL,OPEN_DEL_MODAL, CLEAR_MODAL_DATA } from "../actions/actionTypes";

const initialState = {
  isOpen: false,
  isDelOpen:false,
  isEditOpen:false,
  roomName:"",
  current_username: "",
  feedId: "",
  topicId: "",
  roomId:"",
  userId:""
};
interface IAction {
  type: string;
  current_username: string;
  roomName:string;
  feedId: any
  topicId: string;
  roomId: string;
  payload: object;
  userId:any;
}

const modalReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case OPEN_MODAL: {
      if (action.current_username){
      return {
        ...state,
        isOpen: true,
        current_username: action.current_username,
        userId:action.userId
      };
    } else if (action.feedId) {
      return {
        ...state,
        isOpen: true,
        feedId: action.feedId,
      };
    }
    else{
      return {
        ...state,
        isOpen: true,
      };
    }
  }

 
  case OPEN_DEL_MODAL:{
    return{
      ...state,
      isDelOpen:true,
      roomName:action.roomName,
      topicId: action?.topicId,
      roomId:action.roomId,
    }
    
  }

    case CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false,
        isDelOpen:false,
        current_username:"",
        topicId: "",
        feedId: ""
      };
    }
    case CLEAR_MODAL_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
