import { useEffect } from "react";
import "./BackgroundImage.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import Header from "./../../components/Header";
import AddBackgroundCard from "./AddBackgroundCard/Index";
import { getAllBackgroundImageActions } from "../../actions/backgroundImageActions";

const AddBackground = (props) => {
  const { backgroundImages } = props;
  if (backgroundImages && backgroundImages.length) {
    return (
      <ul>
        <AddBackgroundCard />
      </ul>
    );
  } else {
    return <AddBackgroundCard />;
  }
};

const BackgroundImage = () => {
  const dispatch = useDispatch();
  const environments = useSelector((state: RootState) => state.environmentsReducers.environments);
  const REACT_APP_COMPANY_NAME = environments && environments.REACT_APP_COMPANY_NAME !== undefined ? environments.REACT_APP_COMPANY_NAME.value : "";
  const backgroundImages = useSelector(
    (state: RootState) => state.backgroundImage.allBackgroundImage
  );

  useEffect(() => {
    dispatch(
      getAllBackgroundImageActions(REACT_APP_COMPANY_NAME as string)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <Header />
      <div className="team_con">
        <AddBackground backgroundImages={backgroundImages} />
      </div>
    </>
  );
};

export default BackgroundImage;
