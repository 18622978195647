import request from "./index";
import qs from "querystring";
import { reportRequest } from "./index";
import { getToken } from "../utils/Token";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": getToken()
  },
};

export const getWebrtcStats = async () => {
  config['headers']['Authorization'] = getToken();
  const webrtcStats = await reportRequest.get("/v1/api/webrtc/get-filtered-stats", config);
  return webrtcStats.data;
};

export const getFilteredStats = async (starttime: string, endtime: string, username: string,roomname: string) => {
  config['headers']['Authorization'] = getToken();
  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: username,
    roomId: roomname
  };
  const webrtcStats = await reportRequest.post("/v1/api/webrtc/get-filtered-stats", requestBody, config);
  return webrtcStats.data;
};

export const clearStats = async (type: string) => {
  config['headers']['Authorization'] = getToken();
  const requestBody = {
    type
  };
  const webrtcStats = await reportRequest.post("/v1/api/webrtc/clear-stats", requestBody, config);
  return webrtcStats.data;
};