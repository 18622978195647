import request, { authRequest } from "./index";
import { getToken } from "../utils/Token";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getCurrentUsers = async () => {
  // const userDetailsResponse = await request.get("/getLoggedInUsers");
  // return userDetailsResponse.data;
  config['headers']['Authorization'] = getToken();
  try {
    const res = await authRequest.get("v1/api/user/get-loggedin-users", config);
    return res.data;
  } catch (err: unknown | any) {
    throw new Error(`Error Get LoggeIn User ${err.message}`);
  }
};
