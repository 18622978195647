import {GET_FEEDBACKIMAGES_SUCCESS, GET_FEEDBACKIMAGES_FAILURE,CLEAR_FEEDBACKIMAGES_DATA} from "../actions/actionTypes";

const initialState = {
    images: [],
    successMessage: "",
    failureMessage: "",
}

interface IAction {
    type: string;
    payload: any;
}

const feedbackImagesReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_FEEDBACKIMAGES_SUCCESS: {
            return {
                ...state,
                images: action.payload?.images
            }
        }

        case GET_FEEDBACKIMAGES_FAILURE: {
            return {
                ...state,
                failureMessage: action.payload?.errorMessage
            }
        }
        
        case CLEAR_FEEDBACKIMAGES_DATA:{
            return initialState
          }
        default: {
            return state;
        }
    }
}

export default feedbackImagesReducer;