import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_ALL_USER_DETAILS_SUCCESS,
  GET_ALL_USER_DETAILS_FAILURE,
  SET_USERS_LIST_SUCCESS,
  SET_USERS_LIST_FAILURE
} from "./actionTypes";
import { viewAllUsers,listAllUsers } from "../services/viewAllUsers";

export const handleViewAllUsers = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await viewAllUsers();
      if (responseData.success) {
        dispatch(setSuccess(responseData));
      } else {
        dispatch(setViewAllUsersError("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setViewAllUsersError(error.message.message));
      } else {
        dispatch(setViewAllUsersError("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setSuccess = (responseData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ALL_USER_DETAILS_SUCCESS,
      payload: responseData,
    });
  };
};

const setViewAllUsersError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ALL_USER_DETAILS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

export const listAllUsersActon = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const usersList = await listAllUsers();
      if (usersList.success) {
        dispatch(setUserListSuccess(usersList.users));
      } else {
        console.log({usersList});
        dispatch(setUserListFailure("Error in retrieving"));
      }
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setUserListFailure(error.message.message));
      } else {
        console.log({error})
        dispatch(setUserListFailure("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setUserListSuccess = (users: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_USERS_LIST_SUCCESS,
      payload: {
        users,
      },
    });
  };
};

const setUserListFailure = (msg:string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_USERS_LIST_FAILURE,
      payload: {
        msg
      },
    });
  };
};

