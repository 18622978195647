import { getToken } from "../utils/Token";
import { utilRequest } from "./index";

export const getReleaseNotes = async () => {
    const config_data = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    config_data['headers']['Authorization'] = getToken();
    const response = await utilRequest.get("/v1/api/release-notes/get", config_data);
    return response?.data?.data;
};