import {
    FETCH_RELEASE_NOTES_REQUEST,
    FETCH_RELEASE_NOTES_SUCCESS,
    FETCH_RELEASE_NOTES_FAILURE,
} from "../actions/actionTypes";

const initialState = {
    loading: false,
    releaseNotes: [],
    error: "",
};

interface IAction {
    type: string;
    payload?: any;
    error?: string;
}

const releaseNotesReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case FETCH_RELEASE_NOTES_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case FETCH_RELEASE_NOTES_SUCCESS: {
            return {
                ...state,
                loading: false,
                releaseNotes: action.payload,
            };
        }
        case FETCH_RELEASE_NOTES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error || "",
            };
        }
        default: {
            return state;
        }
    }
};

export default releaseNotesReducer;
