import {
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAILURE,
  CREATE_TOPIC_SUCCESS,
  CREATE_TOPIC_FAILURE,
  EDIT_TOPIC_SUCCESS,
  EDIT_TOPIC_FAILURE,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_FAILURE,
  CLEAR_TOPICS_DATA,
} from "../actions/actionTypes";

// _id(pin):"64ad19c344ada1bde4698db8"
// username(pin):"JAGDEEPSINGH"
// topic(pin):"GLOBAL WARMING"
// description(pin):"discuss here about issues related to global warming New Edited Test janghuuuu Test"
// client(pin):"collaboration"
// createdAt(pin):"2023-07-11T08:58:43.980Z"
// __v(pin):0

interface Topic {
  _id: string;
  username: string;
  topic: string;
  descriptions: string;
  client: string;
  createdAt: string;
  read?:boolean,
  write?:boolean
  __v: number;
}

interface IInitialStateType {
  topics: Array<Topic>;
  successMessage: string;
  failureMessage: string;
  delSuccessMessage: string;
  delFailureMessage: string;
  editSuccessMessage: string;
  editFailureMessage: string;
}

const initialState: IInitialStateType = {
  topics: [],
  successMessage: "",
  failureMessage: "",
  delSuccessMessage: "",
  delFailureMessage: "",
  editSuccessMessage: "",
  editFailureMessage: "",
};

interface IAction {
  type: string;
  payload: any;
}

export const topicsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_TOPICS_SUCCESS: {
      return {
        ...state,
        topics: action.payload.topics,
      };
    }

    case GET_TOPICS_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload,
      };
    }

    case CREATE_TOPIC_SUCCESS: {
      return {
        ...state,
        successMessage: "Topic Created successfully",
        topics: [...state.topics, action.payload],
      };
    }

    case CREATE_TOPIC_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload,
      };
    }

    case EDIT_TOPIC_SUCCESS: {
      return {
        ...state,
        editSuccessMessage: action.payload.message,
        topics: state.topics.map((topicc: Topic) =>
          topicc._id !== action.payload.topicId
            ? topicc
            : {
                ...topicc,
                topic: action.payload.topic,
                description: action.payload.description,
                bussId: action.payload.bussunit,
                permissions:action.payload.permissions,
              }
        ),
      };
    }

    case EDIT_TOPIC_FAILURE: {
      return {
        ...state,
        editFailureMessage: action.payload,
      };
    }

    case DELETE_TOPIC_SUCCESS: {
      return {
        ...state,
        delSuccessMessage: action.payload.message,
        topics: state.topics.filter(
          (topic: any) => topic._id !== action.payload.id
        ),
      };
    }

    case DELETE_TOPIC_FAILURE: {
      return {
        ...state,
        delFailureMessage: action.payload,
      };
    }

    case CLEAR_TOPICS_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
