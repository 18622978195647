import { getToken } from "../utils/Token";
import request, { authRequest } from "./index";
import qs from "querystring";

interface sendData {
  accountSid:string,
  authToken:string,
  date:string,  
  mobile?:string,
  otpMessage?:string,
}

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const saveTwilioSetting = async ( AccountSid : string, AuthToken : string, date:string,mobile?:string,mobileotpmessage?:string) => {
  let data :sendData = {    
    accountSid: AccountSid,
    authToken: AuthToken,
    date:date,
    mobile:mobile,
    otpMessage:mobileotpmessage,   
  } 

  config["headers"]["Authorization"] = getToken();
  const requestBody = qs.stringify({...data});
  const response = await authRequest.post("/v1/api/twilio/save", requestBody, config);
  return response.data;
};

