import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_TWILIO_SETTING_DETAILS_SUCCESS,
  GET_TWILIO_SETTING_DETAILS_FAILURE,
} from "./actionTypes";
import { getTwilioSettingDetail } from "../services/getTwilioSettingDetail";

export const handleGetTwillioSettingDetail = (sno?: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (sno) {
        const responseData = await getTwilioSettingDetail(sno);
        if (responseData.success) {
          dispatch(setSuccess(responseData));
        } else {
          dispatch(setGetUserDetailError("Incorrect Fields"));
        }
        return responseData;
      } else {
        const responseData = await getTwilioSettingDetail();
        if (responseData.success) {
          console.log('zzzz');
          dispatch(setSuccess(responseData));
        }
        return responseData;
      }
    } catch (error: any) {
      if (sno) {
        if (error.message && error.message.message) {
          dispatch(setGetUserDetailError(error.message.message));
        } else {
          dispatch(setGetUserDetailError("Incorrect Fields"));
        }
      } else {
        dispatch(setGetUserDetailError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setSuccess = (responsedata: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_TWILIO_SETTING_DETAILS_SUCCESS,
      payload: responsedata,
    });
  };
};

const setGetUserDetailError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_TWILIO_SETTING_DETAILS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
