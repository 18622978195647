import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { ADD_USER_SUCCESS, ADD_USER_FAILURE, SET_MFA } from "./actionTypes";
import { addUser, addMultipleUsers } from "../services/addUser";
import { toast } from "react-toastify";

export const setMfaAction = (mfa: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_MFA,
      payload: { mfa },
    });
  };
};

export const handleAddUser = (
  username: string,
  email: string,
  password: string,
  role: string,
  firstName: string,
  lastName: string,
  mfa: boolean,
  complexPassword: boolean,
  date: string,
  faceVerification: boolean,
  appUpdate: boolean,
  enableDesktopApp: boolean,
  team?: string,
  bussId?: string,
  mobile?: string,
  selectedGroupRoom?: Array<string>
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    console.log( username,
      team,"%%%%%%%%%%%%%",
      bussId,);
    
    try {
      if (username && password && role && firstName && lastName) {
        const responseData = await addUser(
          username,
          email,
          password,
          role,
          firstName,
          lastName,
          date,
          mfa,
          faceVerification,
          appUpdate,
          enableDesktopApp,
          complexPassword,
          team,
          bussId,
          mobile,
          selectedGroupRoom
        );
        if (responseData) {
          dispatch(setAddUserError(""));
          dispatch(setSuccess());
          toast(responseData.message);
        }
        return responseData;
      }
    } catch (error: any) {
      if (username && password) {
        if (
          error.response.data.message 
        ) {
          dispatch(setAddUserError(error.response.data.message));
        } else if (email) {
          dispatch(setAddUserError("Email already exists"));
        } else {
          dispatch(setAddUserError("Incorrect Fields"));
        }
      } else {
        dispatch(setAddUserError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleAddMultipleUsers = async (file) => {
  try {
    // let fileReader = new FileReader();
    // let users: any[] = [];

    // fileReader.onload = function (e) {
    //   if (e.target) {
    //     var data = e.target.result;
    //     let workbook = XLSX.read(data, { type: "binary" });
    //     workbook.SheetNames.forEach((sheet) => {
    //       let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
    //       users.push.apply(users, rowObject);
    //     });
    //     addMultipleUsers(users);
    //   }
    // };
    // fileReader.readAsBinaryString(file);
    let response = await addMultipleUsers(file);
    return response;
  } catch (error) {
    return {
      success: false,
      message: { message: "Users couldn't be created" },
    };
  } finally {
  }
};

const setSuccess = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADD_USER_SUCCESS,
    });
  };
};

const setAddUserError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADD_USER_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
