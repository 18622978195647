import { Dispatch } from "react";
import { addLogo } from "../services/addLogo";
import {
    CLOSE_MODAL,
    ADD_LOGO_SUCCESS,
    ADD_LOGO_FAILURE,
} from "./actionTypes";

export const addLogoAction = (logoName: any, setLoader: any) => {
    return async (dispatch: Dispatch<any>) => {
        setLoader(true);
        try {
            const addLogoResponse = await addLogo(logoName);
            console.log(addLogoResponse);
            if (addLogoResponse) {
                dispatch(addLogoSuccess(addLogoResponse));
            } else {
                dispatch(
                    addLogoFailure("Could not edit room. Please try again")
                );
            }
            dispatch({ type: CLOSE_MODAL });
            setLoader(false);
            return addLogoResponse;
        } catch (error: any) {
            setLoader(false);
            addLogoFailure(
                error ? error?.message : "Could not edit room. Please try again"
            );
        } finally {
            setLoader(false);
        }
    };
};

const addLogoSuccess = (addLogoResponse) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: ADD_LOGO_SUCCESS,
            payload: addLogoResponse
        });
    };
};

const addLogoFailure = (errorMessage: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: ADD_LOGO_FAILURE,
            error: errorMessage,
        });
    };
};