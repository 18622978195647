import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAILURE,
  SET_EXPORT_LOADING_FALSE,
  SET_EXPORT_LOADING_TRUE,
  SET_EXPORT_DATA,
  GET_LOCATION_DATA_SUCCESS
} from "./actionTypes";
import {
  getUserLocationData,
  getReportData,
  getExportSessionData,
  getExportExpressionData,
  getExportSummaryData,
  getExportNotLoggedData,
  getExportViolationData,
} from "../services/getReportData";
import { toast } from "react-toastify";

export const getReportDatam = (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string,
  pagenumber: number
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getReportData(
        starttime,
        endtime,
        rname,
        roomname,
        pagenumber
      );
      if (responseData.success) {
        dispatch(setSuccess(responseData));
        // if(responseData.data[3][0] <= 0){
        //   toast("Not data found")
        // }
      } else {
        dispatch(setGetReportError("Incorrect Fields"));
        toast.error("Please ensure start time is less than end time");
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setGetReportError(error.message.message));
      } else {
        dispatch(setGetReportError("Incorrect Fields"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getExportDataActions = (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string,
  dataType: string
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_EXPORT_LOADING_TRUE });
    try {
      let responseData;

      switch (dataType) {
        case "expression":
          responseData = await getExportExpressionData(
            starttime,
            endtime,
            rname,
            roomname
          );
          break;
        case "summary":
          responseData = await getExportSummaryData(
            starttime,
            endtime,
            rname,
            roomname
          );
          break;
        case "session":
          responseData = await getExportSessionData(
            starttime,
            endtime,
            rname,
            roomname
          );
          break;
        case "notLogged":
          responseData = await getExportNotLoggedData(
            starttime,
            endtime,
            rname,
            roomname
          );
          break;
        case "violation":
          responseData = await getExportViolationData(
            starttime,
            endtime,
            rname,
            roomname
          );
          break;
        default:
          break;
      }
      if (responseData.success) {
        dispatch({
          type: SET_EXPORT_DATA,
          payload: responseData.data,
        });
      } else {
        dispatch(setGetReportError("Incorrect Fields"));
        toast.error("Please ensure start time is less than end time");
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setGetReportError(error.message.message));
      } else {
        dispatch(setGetReportError("Incorrect Fields"));
      }
    } finally {
      dispatch({ type: SET_EXPORT_LOADING_FALSE });
    }
  };
};

const setSuccessLocation = (responsedata: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_LOCATION_DATA_SUCCESS,
      payload: responsedata,
    });
  };
};

const setSuccess = (responsedata: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: responsedata,
    });
  };
};

const setGetReportError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_REPORT_DATA_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

export const getUserLocation = (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string,
  pagenumber: number
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getUserLocationData(
        starttime,
        endtime,
        rname,
        roomname,
        pagenumber
      );
      if (responseData.success) {
       dispatch(setSuccessLocation(responseData));
      } else {
        dispatch(setGetReportError("Incorrect Fields"));
        toast.error("Please ensure start time is less than end time");
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setGetReportError(error.message.message));
      } else {
        dispatch(setGetReportError("Incorrect Fields"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};
