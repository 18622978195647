import {
  TWILLIO_SETTING_SUCCESS,
  TWILLIO_SETTING_FAILURE,
  CLEAR_TWILLIO_DATA
  } from "../actions/actionTypes";
  
  const initialState = {
    isSettingSaved: false,
    error: "",
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const twilioSettingReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case TWILLIO_SETTING_SUCCESS: {
        return {
          ...state,
          isSettingSaved: true,
        };
      }
      case TWILLIO_SETTING_FAILURE: {
        return {
          ...state,
          isSettingSaved: false,
          error: action.payload.error,
        };
      }
      case CLEAR_TWILLIO_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default twilioSettingReducer;
  