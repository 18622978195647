import {authRequest} from "./index";
import {getToken} from "../utils/Token";
const config = {
  headers:{
    "Content-Type": "application/json",
    "Authorization": ""
  }
}

export const getTwilioSettingDetail = async (sno?: string) => {
   
  config["headers"]["Authorization"] = getToken();
  const twilioDetailsResponse = await authRequest.get("/v1/api/twilio/get",config);
  return twilioDetailsResponse.data;
};
