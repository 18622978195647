import { Dispatch } from "react";
import {
  SET_ALL_GROUP_ROOM_DATA,
  SET_LOADER_FOR_GROUP_ROOM,
} from "./actionTypes";
import { getGroupRooms } from "../services/groupRoom";
import { toast } from "react-toastify";

export const getAllGroupRoom = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_LOADER_FOR_GROUP_ROOM,
      payload: {
        loader: true,
      },
    });
    try {
      const responseData = await getGroupRooms();
      if (responseData.success) {
        dispatch({
          type: SET_ALL_GROUP_ROOM_DATA,
          payload: {
            allGroupRoom: responseData.groupRooms,
          },
        });
      } else {
        toast("There is issue while reteriving group please reload your page");
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        toast(`Error is ${error.message.message} please reload your page`);
      } else {
        toast("There is issue while reteriving group please reload your page");
      }
    } finally {
      dispatch({
        type: SET_LOADER_FOR_GROUP_ROOM,
        payload: {
          loader: false,
        },
      });
    }
  };
};
