import {Dispatch} from "react";
import {getEnvironments} from "../services/environment";
import {
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_FAILURE
} from "./actionTypes";

export const getEnvironmentsAction = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const environmentData = await getEnvironments();
      let some:any = {};
      // console.log(environmentData, 'environment')
      if(environmentData.success) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, array-callback-return
        const structEnvironment = environmentData.environments.map((item:any) => {
            some[item.variable] = item;
        });
        if(some && some.ACTIVITY_CHANNEL_SERVER_URL !== undefined) {
          localStorage.setItem("activityChannel", some.ACTIVITY_CHANNEL_SERVER_URL.value)
        }
        dispatch({
          type: GET_ENVIRONMENTS_SUCCESS,
          payload: some
        })
      } else {
        dispatch({
          type: GET_ENVIRONMENTS_FAILURE,
          payload: "Could not fetch room details. Please try again"
        })
      }
    } catch (error: any) {
      dispatch({
        type: GET_ENVIRONMENTS_FAILURE,
        payload: error?.message || "Could not fetch room details. Please try again"
      })
    }
  }
}
