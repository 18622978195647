import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
    GET_UNIQUE_LOGIN_USERS_SUCCESS,
    CLEAR_UNIQUE_LOGIN_USERS,
    GET_USERS_BY_ROOM
} from "./actionTypes";
import { getUniqueLoginUsers, getUsersByRoom } from "../services/getReportData";
import { toast } from "react-toastify";


export const getUniqueLoginUsersAction = (starttime:string,endtime:string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setLoading(true));
        try {
  
            const responseData = await getUniqueLoginUsers(starttime,endtime);
            if (responseData.success) {
              dispatch({
                type:GET_UNIQUE_LOGIN_USERS_SUCCESS,
                payload:{allUsers:responseData.data,usersLength:responseData.length}
              });
             
            } else {
              toast.error("Please ensure start time is less than end time");
            }
            
           
        } catch (error: any) {
      
            if (error.message && error.message.message) {
              toast.error(error.message.message);
            } else {
              toast.error("Incorrect Fields");
            }
        
          
        } finally {
          dispatch(setLoading(false));
        }
      };
  };



  

export const getUsersByRoomAction = (starttime:string,endtime:string,room:Array<string> ) => {
  return async (dispatch: Dispatch<any>) => {
      dispatch(setLoading(true));
      try {

          const responseData = await getUsersByRoom(starttime,endtime,room);
          if (responseData.success) {
            dispatch({
              type:GET_USERS_BY_ROOM,
              payload:responseData.data
            });
           
          } else {
            toast.error("Please ensure start time is less than end time");
          }
          
         
      } catch (error: any) {
    
          if (error.message && error.message.message) {
            toast.error(error.message.message);
          } else {
            toast.error("Incorrect Fields");
          }
      
        
      } finally {
        dispatch(setLoading(false));
      }
    };
};
  

  export const clearUniqueLoginUsers = ()=>  {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type:CLEAR_UNIQUE_LOGIN_USERS
        })
       
  };
}
  
  
  
  