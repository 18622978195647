import React from "react";
import "./style.css";

interface ILoaderProps {
    message?: string;
}

const Loader = (props: ILoaderProps) => {
    return (
        <React.Fragment>
            <div className="loader ">
                {props.message && <div>{props.message}</div>}
            </div>
        </React.Fragment>
    );
}

export default Loader;