import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { timeZoneFormat } from "../../utils/dateformat/index";
import moment from "moment";
import ModalDoughnutChart from "../ModalDoughnutChart";
import "./index.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export function ModalBarChart(props) {
  const chartRef = React.useRef<any>();
  const [, setDoughNutData] = React.useState<any>();
  const doughNutRef = React.useRef<any>(null);
  const { format } = timeZoneFormat();
  const {activityFilter, setActivityFilter, desktopApps} = props;
  const onClick = (event) => {
    const elem = getElementAtEvent(chartRef.current, event);
    // setDoughNutData(props.activityDetailed[elem[0].index]);
    // doughNutRef.current = props.activityDetailed[elem[0].index];
    console.log(elem[0]);
    let dou = structuredClone(props.activityDetailed[elem[0].index]);

    for (const [appName, ] of Object.entries(
      dou.activity
    )) {
      if(!activityFilter.productive && desktopApps.productive.includes(appName)) {
        delete dou.activity[appName];
      } else if(!activityFilter.nonProductive && desktopApps.nonProductive.includes(appName)) {
        delete dou.activity[appName];
      } else if(!activityFilter.ideal && desktopApps.ideal.includes(appName)) {
        delete dou.activity[appName];
      }
    }
    setDoughNutData(dou);
    doughNutRef.current = dou;
    console.log(dou);
    console.log(props.activityDetailed, { doughNutRef });
  };

  const convertToMinutes = (time: number) => {
    return Math.floor(time / 60) || 1;
  };

  const productiveData = [];
  // eslint-disable-next-line array-callback-return
  props.activityDetailed.map((activity) => {
    // @ts-ignore
    productiveData.push(convertToMinutes(activity.productiveTime));
  });

  const nonProductiveData = [];
  // eslint-disable-next-line array-callback-return
  props.activityDetailed.map((activity) => {
    // @ts-ignore
    nonProductiveData.push(convertToMinutes(activity.nonProductiveTime));
  });

  const neutralData = [];
  // eslint-disable-next-line array-callback-return
  props.activityDetailed.map((activity) => {
    // @ts-ignore
    neutralData.push(convertToMinutes(activity.neutralAppTime));
  });

  const alertData = [];
  // eslint-disable-next-line array-callback-return
  props.activityDetailed.map((activity) => {
    // @ts-ignore
    activity?.screenshots ? alertData.push(activity?.screenshots.length) : alertData.push(0);
  });

  const labels = [];
  // eslint-disable-next-line array-callback-return
  props.activityDetailed.map((activity,index) => {
    // @ts-ignore
    labels.push(`${moment(activity.createdAt).format(format).split(" ")[0]} - Alerts : ${alertData[index]}`);
  });

  const checkFilter = (e) => {
    console.log(e.target.name);
    doughNutRef.current = null;
    if(e.target.name === "productive") {
      setActivityFilter({...activityFilter, 
        productive: e.target.name === true || e.target.value === "true" ? false: true})
    } else if(e.target.name === "nonProductive") {
      setActivityFilter({...activityFilter, 
        nonProductive: e.target.name === true || e.target.value === "true" ? false: true})
    } else if (e.target.name === "ideal") {
      setActivityFilter({...activityFilter, 
        ideal: e.target.name === true || e.target.value === "true" ? false: true})
    }
    // setActivityFilter({...activityFilter, })
  }
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Date Range Activity in Minutes",
      },
      // tooltips: {
      //   mode: 'index',
      //   intersect: true,
      //   custom: function (tooltipModel) {
      //     // Tooltip Element
      //     let tooltipEl = document.getElementById('custom-tooltip');
    
      //     
      //     if (!tooltipEl) {
      //       tooltipEl = document.createElement('div');
      //       tooltipEl.id = 'custom-tooltip';
      //       tooltipEl.classList.add('custom-tooltip');
      //       document.body.appendChild(tooltipEl);
      //     }
    
      //     
      //     if (tooltipModel.opacity === 0) {
      //       tooltipEl.style.display = 'none';
      //       return;
      //     }
    
      //     
      //     const tooltipIndex = tooltipModel.dataPoints[0].index;
      //     const tooltipLabel = data.labels[tooltipIndex];
      //     const tooltipDatasets = data.datasets.filter(dataset => dataset.data[tooltipIndex] !== 0);
          
      //     tooltipEl.innerHTML = `
      //       <div class="tooltip-header">${tooltipLabel}</div>
      //       ${tooltipDatasets.map(dataset => `
      //         <div class="tooltip-body">
      //           <span class="tooltip-label">${dataset.label}: </span>${dataset.data[tooltipIndex]}<br />
      //           <span class="tooltip-label">Additional: </span>${dataset.alertData[tooltipIndex]}
      //         </div>
      //       `).join('')}
      //     `;
    
      //     
      //     tooltipEl.style.opacity = "1";
      //     tooltipEl.style.position = 'absolute';
      //     tooltipEl.style.left = `${tooltipModel.caretX}px`;
      //     tooltipEl.style.top = `${tooltipModel.caretY}px`;
      //     tooltipEl.style.pointerEvents = 'none';
      //   },
      // },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const additionalValue = alertData[tooltipItem.index];
  
          let label = `${datasetLabel}: ${value}`;
          if (additionalValue) {
            label += ` (Additional: ${additionalValue})`;
          }
  
          return label;
        },
      },
    },
  
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#111",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#111",
        },
      },
    },
    devicePixelRatio: 5,
  };

  console.log("activity filter", activityFilter);
  const data = {
    labels,
    datasets: [
      ...activityFilter.productive ?
      [{
        label: "Productive",
        data: productiveData,
        backgroundColor: "#36A2EB",
        alertData:alertData
      }] : [],
      ...activityFilter.nonProductive ?
      [{
        label: "Non-Productive",
        data: nonProductiveData,
        backgroundColor: "#FF6384",
        alertData:alertData
        
      }]: [],
      ...activityFilter.ideal ?
      [{
        label: "Unclassified",
        data: neutralData,
        backgroundColor: "#abaaa9",
        alertData:alertData
      }]: [],
    ],
  };
  return (
    <>
      <div className="bar-chart-container">
        <Bar onClick={onClick} options={options} data={data} ref={chartRef} />
        <div className="activity-filters">
            <div>
                <input type="checkbox" id="productive" name="productive"
                checked={activityFilter.productive}
                onClick={e => checkFilter(e)} 
                value={activityFilter.productive} />
                <label htmlFor="productive">Productive</label>
            </div>
            <div>
                <input type="checkbox" id="nonProductive" name="nonProductive" 
                checked={activityFilter.nonProductive}
                onClick={e => checkFilter(e)} 
                value={activityFilter.nonProductive} />
                <label htmlFor="nonProductive">Non Productive</label>
            </div>
            <div>
                <input type="checkbox" id="ideal" name="ideal" 
                checked={activityFilter.ideal}
                onClick={e => checkFilter(e)} 
                value={activityFilter.ideal}/>
                <label htmlFor="ideal">Unclassified</label>
            </div>
        </div>
        {!doughNutRef.current && (
          <p className="post-text">Expand for date-specific details*</p>
        )}

        {doughNutRef.current && (
          <>
            <hr />
            <div className="app-time-container">
              <p className="app-time-heading">Application Time Report</p>
              {
                Object.keys(doughNutRef.current.activity).length > 0 ? <ModalDoughnutChart 
                doughNutData={doughNutRef.current}
                activityFilter={activityFilter} 
               /> : "There is no Activity to display"
              }
              
            </div>
          </>
        )}
      </div>
    </>
  );
}
