import { getToken } from "../utils/Token";
import request, { authRequest } from "./index";


interface sendData {
  username: string;
  email: string;
  password: string;
  role: string;
  firstName: string;
  lastName: string;
  date: string;
  mfa: boolean;
  appUpdate: boolean;
  faceVerification: boolean;
  enableDesktopApp: boolean;
  // complexPassword: boolean,
  bussId?: string;
  roomId?: string;
  mobile?: string;
  groupRoomsId?: Array<string>;
}

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""
  },
};

export const addUser = async (
  username: string,
  email: string,
  password: string,
  role: string,
  firstName: string,
  lastName: string,
  date: string,
  mfa: boolean,
  faceVerification: boolean,
  appUpdate: boolean,
  enableDesktopApp: boolean,
  complexPassword?: boolean,
  team?: string,
  bussId?: string,
  mobile?: string,
  selectedGroupRoom?: Array<string>
) => {
  let data: any = {
    username: username,
    email: email,
    password: password,
    role: role,
    firstName: firstName,
    lastName: lastName,
    date: date,
    mfa: mfa,
    bussId: bussId,
    // complexPassword: complexPassword
    appUpdate: appUpdate,
    "faceVerification.isFaceVerificationEnabled": faceVerification,
    enableDesktopApp: enableDesktopApp,
  };

  if (
    bussId !== "" &&
    ((selectedGroupRoom && selectedGroupRoom.length > 0) ||
      role === "Director" ||
      role === "Manager")
  ) {
    data = { ...data, bussId: bussId };
  } else if ((role === "Supervisor" || role === "Agent") && bussId && team) {
    data = { ...data, bussId: bussId, roomId: team };
  }
  if (selectedGroupRoom && selectedGroupRoom.length > 0 && role !== "Admin") {
    data = { ...data, groupRoomsId: selectedGroupRoom };
  }

  if(team!==""){
    data = { ...data,  roomId: team };
  }

  if (mfa) {
    data = { ...data, mobile: mobile };
  }

  config["headers"]["Authorization"] = getToken();

  // console.log({ data });

  const response = await authRequest.post("/v1/api/user/add", data, config);

  return response.data;
};


export const addMultipleUsers = async (file) => {
  config["headers"]["Authorization"] = getToken();
  // console.log("oops", users);
  let formData = new FormData();
  formData.append("file", file);
  const response = await authRequest.post("/v1/api/user/add-bulk", formData,config);
  return response.data;
};
