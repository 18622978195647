import { getToken } from "../utils/Token";
import request, { utilRequest } from "./index";
import qs from "querystring";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""
  },
};

export const AdminSettings = async () => {
  config["headers"]["Authorization"] = getToken();
  const AdminSettingResponse = await utilRequest.get("/v1/api/environments/get-env-admin",config);
  return AdminSettingResponse.data;
};

//updateStatus
export const updateStatusCall = async (
  type: string,
  variable: string,
  status: boolean
) => {
  // console.log("This is Update Status value =>"+variable +", Status =>"+status);
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    type: type,
    variable: variable,
    status: status ? false : true,
  };

  // const response = await request.post("/adminlogin", requestBody, config);

   await utilRequest.post(
    "/v1/api/environments/update-env-admin",
    requestBody,
    config
  );

  const AdminSettingResponse2 = await utilRequest.get("/v1/api/environments/get-env-admin",config);
  return AdminSettingResponse2.data;
  //return AdminSettingResponse.data;
};
export const updateEmailPerm = async (
  type: string,
  variable: string,
  email: boolean
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    type: type,
    variable: variable,
    email: email ? false : true,
  };
  await utilRequest.post("/v1/api/environments/update-env-admin", requestBody, config);
  const AdminSettingResponse2 = await utilRequest.get("/v1/api/environments/get-env-admin",config);
  return AdminSettingResponse2.data;
};

export const updateViolationPerm = async (
  type: string,
  variable: string,
  saveviolation: boolean
) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    type: type,
    variable: variable,
    saveviolation: saveviolation ? false : true,
  };
  await utilRequest.post("/v1/api/environments/update-env-admin", requestBody, config);
  const AdminSettingResponse2 = await utilRequest.get("/v1/api/environments/get-env-admin",config);
  return AdminSettingResponse2.data;
};

export const updateAlertPerm = async (
  type: string,
  variable: string,
  alert: boolean
) => {
  config["headers"]["Authorization"] = getToken();

  const requestBody = {
    type: type,
    variable: variable,
    alert: alert ? false : true,
  };
  await utilRequest.post("/v1/api/environments/update-env-admin", requestBody, config);
  const AdminSettingResponse2 = await utilRequest.get("/v1/api/environments/get-env-admin",config);
  return AdminSettingResponse2.data;
};

export const updateNonePerm = async (type: string, variable: string) => {
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    type: type,
    variable: variable,
    alert: false,
    email: false,
    saveviolation: false,
  };
  await utilRequest.post("/v1/api/environments/update-env-admin", requestBody, config);
  const AdminSettingResponse2 = await utilRequest.get("/v1/api/environments/get-env-admin",config);
  return AdminSettingResponse2.data;
};

export const updateValStatusCall = async (variable: string, value: string, type: string) => {
  // console.log("This is Update Status value =>"+variable +", Status =>"+status);
  config["headers"]["Authorization"] = getToken();
  const requestBody = {
    variable: variable,
    value: value,
    type
  };

  //const response = await request.post("/adminlogin", requestBody, config);


  await utilRequest.post(
    "/v1/api/environments/update-env-admin",
    requestBody,
    config
  );
   

  const AdminSettingResponse2 = await utilRequest.get("/v1/api/environments/get-env-admin",config);
  return AdminSettingResponse2.data;
  //return AdminSettingResponse.data;
};
