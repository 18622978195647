import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
// import reportWebVitals from './reportWebVitals';
import { createReduxStore } from "./redux";
import { Provider } from "react-redux";
import ErrorBoundary from "./components/ErrorBoundary";
import { PersistGate } from 'redux-persist/integration/react'


// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

const { store, persistor } = createReduxStore();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

