import { getToken } from "../utils/Token";
import { roomRequest } from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getGroupRooms = async () => {
  config["headers"]["Authorization"] = getToken()
  const groupRoomsResponse = await roomRequest.get("/v1/api/groupRoom/get", config);
  return groupRoomsResponse.data;
};

export const createGroupRoom = async (
  groupname: string,
  rooms: Array<string>,
  bussunit: string
) => {
  const reqBody = qs.stringify({
    groupName : groupname,
    roomsId : rooms,
    bussId : bussunit,
  });

  const createGroupRoomResponse = await roomRequest.post(
    "/v1/api/groupRoom/create",
    reqBody,
    config
  );
  return createGroupRoomResponse.data;
};

export const deleteGroupRoom = async (groupname: string, groupId: string) => {
  const reqBody = qs.stringify({
    groupName : groupname,
    groupId : groupId,
  });

  const delGroupRoomResponse = await roomRequest.post(
    "/v1/api/groupRoom/delete",
    reqBody,
    config
  );
  return delGroupRoomResponse.data;
};

export const editGroupRoom = async (
  groupId : string,
  groupname: string,
  rooms: Array<string>,
  bussunit: string
) => {
  const reqBody = qs.stringify({
    groupId,
    groupName : groupname,
    roomsId : rooms,
    bussId : bussunit,
  });

  const editGroupRoomResponse = await roomRequest.post(
    "/v1/api/groupRoom/edit",
    reqBody,
    config
  );
  return editGroupRoomResponse.data;
};
