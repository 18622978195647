import { useEffect, useState } from "react";
import { Modal, Table, Badge } from "react-bootstrap";
import "./DesktopActivityModal.css";
import { PieChart } from "react-minimal-pie-chart";
import moment from "moment";
import { timeZoneFormat } from "../../utils/dateformat";

const DesktopActivityModal = (props) => {
  const { setModal, setModalOpen, totalTime, activity } = props;
  const [activityData, setactivityData] = useState([] as any);
  const [tableContent, setTableContent] = useState([]);
  const [tableTitle, setTableTitle] = useState(
    "Click on Chart to see more information"
  );
  const {format} = timeZoneFormat();

  const handleModalClose = () => {
    setModalOpen(false);
    setTableContent([]);
    setTableTitle('Click on Chart section to see more information');
  };
  const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second " : " seconds") : "";

    return hDisplay + mDisplay + sDisplay;
  };

  console.log({activity});

  const randomHexColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  const getChartData = () => {
    let formatedData = [];
    if (activity.activity) {
      const acti = activity.activity;

      //@ts-ignore
      Object.entries(acti).forEach(([key, val]: any) => {
        const programs = [];
        let totalTimeOnApp = 0;

        //@ts-ignore
        Object.entries(val).forEach(([prop, meta]: any) => {
          //@ts-ignore
          totalTimeOnApp += meta.time;
          //@ts-ignore
          programs.push({
            name: prop ? prop : "Not Available",
            url: meta.url,
            time: meta.time,
          });
        });

        //@ts-ignore
        formatedData.push({
          title:
            key  ? key : 'Not available' +
            " - " +
            secondsToHms(totalTimeOnApp),
          value: Math.floor(totalTimeOnApp),
          color: randomHexColor(),
          data: programs,
        });
      });
    }
    console.log(formatedData);
    return formatedData;
  };

  const chartClickHandler = (e, index) => {
    setTableTitle(activityData[index].title);
    setTableContent(activityData[index].data);
  };

  // const dateToString = (date) => {
  //   const d = new Date(activity.date);
  //   return d.toDateString();
  // }

  useEffect(() => {
    let chd = getChartData();
    setactivityData(chd);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);


  return (
    <Modal
      show={setModal}
      onHide={handleModalClose}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {/* {activity.username} ({dateToString(activity.date)}) - {totalTime} */}
          {activity.username} ({moment(activity.createdAt).format(format).split(' ')[0]}) - {totalTime}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        <div className="row mb-4 ml-4">
            {
                activityData.map((item:any, index) => {
                    return (<>
                    <Badge style={{backgroundColor: item.color, marginRight:"5px", color:"white"}}>
                        {item.title}
                    </Badge>
                    </>)
                })
            }
        </div>
        </div>
        <div className="row ">
          <div className="pie col-md-5">
            <PieChart
              animate={true}
              animationEasing="ease-out"
              paddingAngle={2}
              lineWidth={65}
              center={[50, 50]}
              data={activityData}
              onClick={chartClickHandler}
            />
          </div>
          <div className="cold-md-7 right-cont">
            <h5>{tableTitle}</h5>
            { tableContent.length > 0 ? 
            <Table className="mdl-tbl" striped bordered hover table-fixed>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {
                    tableContent.map((item:any, index) => {
                      return (
                        <tr key={index}>
                          <td title={item.name}>{item.name.length < 38 ? item.name:  item.name.substring(0,38) + "..."}</td>
                          <td>{secondsToHms(item.time)}</td>
                        </tr>
                      );
                    })
  
                }
              </tbody>
            </Table>
            : null
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DesktopActivityModal;
