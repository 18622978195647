import { getToken } from "../utils/Token";
import request, { utilRequest } from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""
  },
};

// export const getRooms = async () => {
//   const userDetailsResponse = await request.get("/getRooms");
//   return userDetailsResponse.data;
// };

export const addBackgroundImage = async (imageName: any, company: string) => {
  const config_img = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": ""
    },
  };

  const data = new FormData();

  data.append("file", imageName);
  config_img["headers"]["Authorization"] = getToken();
  const uploadResponse = await utilRequest.post(
    "/v1/api/backgroundimage/upload",
    data,
    config_img
  );
  return uploadResponse.data;
};

export const getAllBackgroundImage = async (company: string) => {
  config["headers"]["Authorization"] = getToken()
  const allBackgroundImage = await utilRequest.get(
    "/v1/api/backgroundimage/get",
    config
  );
  return allBackgroundImage.data;
};

export const deleteBackgroundImage = async (
  fileId: string,
  filename: string,
  company: string

) => {
  const reqBody = {
    filename,
    fileId,

  };
  config["headers"]["Authorization"] = getToken();
  const uploadResponse = await utilRequest.post(
    "/v1/api/backgroundimage/delete",
    reqBody,
    config
  );
  return uploadResponse.data;
};
