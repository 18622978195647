import React, { useEffect, useState } from "react";
import "./DesktopActivity.css";
import Header from "./../../components/Header";
import { Button, Table, Badge } from "react-bootstrap";
import { DateTimePicker } from "react-rainbow-components";
import { getDesktopActivity } from "../../services/desktopActivity";
// import DesktopChart from "../../components/DesktopChart/DesktopChart";
import DesktopActivityModal from "../../components/DesktopActivityModal/DesktopActivityModal";
import { reportAPI } from "../../utils/api";
import { timeZoneFormat } from "../../utils/dateformat";
import moment from "moment";
import { setLoading } from "../../actions/loaderActions";
import ActivityChart from "../../components/ActivityChart";
import { Modal } from "react-bootstrap";
import { ModalBarChart } from "../../components/ModalBarChart";
import { FormControl,Autocomplete, TextField} from "@mui/material";
import {getDesktopApps} from "../../services/desktopActivity";
import {toast} from "react-toastify";
import { CSVLink } from "react-csv";
import { RootState } from "../../redux";
import { useSelector } from "react-redux";

interface BusinessUnit {
  label: string;
  value: string; // Assuming there's a value too; adjust as per your data structure
}

function MyVerticallyCenteredModal(props) {
  const { activityDetailed, startDate, endDate, totalTime, desktopApps } = props;
  const [activityFilter, setActivityFilter]= useState({
    productive: true,
    nonProductive: true,
    ideal: true
  })
  const [csvData, setCsvData] = React.useState<any>([]);
  
  const username = activityDetailed[0]?.username;
  const { format } = timeZoneFormat();

  const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second " : " seconds") : "";

    return hDisplay + mDisplay + sDisplay;
  };

  const exportToUserActivityData = () => {
    setCsvData([]);
    // setLoadCsvData("loading");
    const summaryArray = [
      ["Username","Date", "Room", "Apps", "Productive", "Non Productive", "Unclassified", "Total"]
    ];
    const templateSummaryData = ["username","createdAt", "roomName","activity","productiveTime", "nonProductiveTime", "neutralAppTime", "totaltime"];
    // eslint-disable-next-line array-callback-return
    activityDetailed.map((value:any) => {
      summaryArray.push(
        templateSummaryData.map((templateValue) => {
          let val = value[templateValue];
          switch(templateValue) {
            case "createdAt":
                val = moment(value[templateValue]).format(format).split(" ")[0];
                break;
            case "activity":
                val = Object.keys(value.activity).join(",");
                break;
            case "productiveTime":
            case "nonProductiveTime":
            case "neutralAppTime":
                val = secondsToHms(value[templateValue]);
                break;
            case "totaltime":
                val = secondsToHms(value.productiveTime+value.nonProductiveTime+value.neutralAppTime);
                break;
          }
          return val;
        })
      );
    });

    setCsvData(summaryArray);
    // setLoadCsvData("complete");
    console.log(summaryArray);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName=" modal-height-dialog"
      contentClassName="modal-height"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="modal-header-container">
            <p>
              {username} <span> Total Time - {totalTime}</span>
            </p>
            <p>{`(${moment(startDate).format(format).split(" ")[0]} - ${
              moment(endDate).format(format).split(" ")[0]
            })`}</p>
          </div>
          {activityDetailed.length > 0 ? <CSVLink
          filename={`summary-by-date-${Date.now()}`}
          className="link"
          data={csvData}
          onClick={(event) => {
            exportToUserActivityData();
          }}
        >
          Export Activity By Date
        </CSVLink>
        : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalBarChart 
          activityDetailed={activityDetailed}
          activityFilter={activityFilter}
          setActivityFilter={setActivityFilter}
          desktopApps={desktopApps}
        />
      </Modal.Body>
    </Modal>
  );
}

const DesktopActivity = (props: any) => {
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [name, setName] = useState({label: "All", value: ""});
  const [activity, setActivity] = useState([]);
  const [activityDetailed, setActivityDetailed] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, ] = useState({});
  const [userTotalTime, ] = useState(0);
  const [nameArray, setNameArray] = useState([]);
  const userRole = useSelector((state: RootState) => state.userDetails.role);
  const [roomArray, setRoomArray] = useState([]);
  const [room, setRoom] = useState({label: "All", value: ""});
  const [bussUnit, setBussunit] = useState({label: "All", value: ""});
  const [bussUnits, setBusUnits] = React.useState<BusinessUnit[]>([]);
  const [open, ] = useState(false);
  const [totalTime, setTotaltime] = useState("");
  const [csvData, setCsvData] = React.useState<any>([]);
  const { format } = timeZoneFormat();
  const [metricsSummary, setMetricsSummary] = useState<
    Array<any>
  >([]);
  const [apps, setApps] = useState<any>({
    productive: [],
    nonProductive: [],
    others: [],
    browser: {}
  });
  const formatDate = (date) => {
    var d = new Date(date);
    return d.toUTCString();
  };

  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      let labeledUsers:any = [];
      // eslint-disable-next-line array-callback-return
      res.data.users.map((user: any, index) => {
        if (user && user.username) {
          // @ts-ignore
          labeledUsers.push({ label: user.username, value: user._id });
        }
      });
      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });
      labeledUsers.unshift({ label: "All Users", value: "All" })
      //@ts-ignore
      setNameArray(labeledUsers);
    });
  };

  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      let labeledRooms = [];
      // eslint-disable-next-line array-callback-return
      res.data.rooms.map((roomValue: any, index) => {
        if (roomValue) {
          // @ts-ignore
          labeledRooms.push({ label: roomValue.roomName, value: roomValue._id });
        }
      });
      setRoomArray(labeledRooms);
    });
  };
  const desktopAppsList = async () => {
    const apiResponse = await getDesktopApps();
    if (apiResponse.success) {
      setApps(apiResponse.data);
    } else {
      toast("Unable to fetch list of Applications");
    }
    console.log("APPS FROM DB: ", apiResponse);
  };
  const getbussunit = () => {
    const filteredBussUnits = [{label: "All Bussunit", value: "All"}];
    reportAPI.getBussUnits().then((res) => {
      // eslint-disable-next-line array-callback-return
      res.data.bussUnits.map((unit) => {
        // @ts-ignore
        filteredBussUnits.push({label: unit.bussUnit, value: unit._id});
      });
    });
    //@ts-ignore
    setBusUnits(filteredBussUnits);
  };
  function EnableF() {
    getuser();
    getrooms();
    getbussunit();
    desktopAppsList();
  }

  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };

  const handleNameChange = (s: any) => {
    console.log({ s });
    if (s.value === "All") {
      setName({label: "All", value: ""});
      return;
    }
    setName(s);
  };

  
  const handleBussUnitChange = (r: any) => {
    if (r.value === "All") {
      setBussunit({label: "All", value: ""});
      return;
    } else {
      setBussunit(r);
    }
    return;
  };

  const exportToActivityData = () => {
    setCsvData([]);
    // setLoadCsvData("loading");
    const summaryArray = [
      ["Username","Apps", "Productive", "Non Productive", "Unclassified", "Total", "Start Date", "End Date"]
    ];
    const templateSummaryData = ["username","allApps","totalProductiveTime", "totalNonProductiveTime", "totalNeutralTime", "totaltime", "startDate", "endDate"];
    // eslint-disable-next-line array-callback-return
    activity.map((value:any) => {
      summaryArray.push(
        templateSummaryData.map((templateValue) => {
          let val = value[templateValue];
          switch(templateValue) {
            case "allApps":
                val = value[templateValue].join(",");
                break;
            case "totalNeutralTime":
            case "totalProductiveTime":
            case "totalNonProductiveTime":
                val = secondsToHms(value[templateValue]);
                break;
            case "totaltime":
                val = getSumOfWholeTime(value.totalProductiveTime, value.totalNonProductiveTime, value.totalNeutralTime);
                break;
            case "startDate": 
                val = moment(startdate).format(format).split(" ")[0];
                break;
            case "endDate":
                val = moment(enddate).format(format).split(" ")[0];
          }
          return val;
        })
      );
    });

    setCsvData(summaryArray);
    // setLoadCsvData("complete");
    console.log(summaryArray);
  };
  
  useEffect(() => {
    EnableF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  useEffect(() => {
    if (activity && activity.length) {
      const sumProductiveTime = activity.reduce(
        // @ts-ignore
        (sum, item) => sum + item.totalProductiveTime,
        0
      );
      const sumNonProductiveTime = activity.reduce(
        // @ts-ignore
        (sum, item) => sum + item.totalNonProductiveTime,
        0
      );
      const sumNeutralTime = activity.reduce(
        // @ts-ignore
        (sum, item) => sum + item.totalNeutralTime,
        0
      );

      // Get the length of the array
      const arrayLength = activity.length;

      const newSummary = [
        { title: "Number of Users:", value: arrayLength },
        {
          title: "Total Productive Time:",
          value: secondsToHms(sumProductiveTime),
        },
        {
          title: "Total Non-Productive Time:",
          value: secondsToHms(sumNonProductiveTime),
        },
        { title: "Total Unclassified Time:", value: secondsToHms(sumNeutralTime) },
        
      ];
      setMetricsSummary(newSummary);
      console.log("----->", {
        metricsSummary,
        sumNeutralTime,
        sumProductiveTime,
        sumNonProductiveTime,
      });
    }
    // console.log("----->mee",{metricsSummary})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second " : " seconds") : "";

    return hDisplay + mDisplay + sDisplay;
  };

  const getSumOfWholeTime = (productive, nProductive, neutral) => {
    let totalTime = productive + nProductive + neutral;
    let secToHms = secondsToHms(totalTime);
    return secToHms;
  };

  const DoSearch = async () => {
    setLoading(true);
    try {
      const desktopActivity = await getDesktopActivity(
        formatDate(startdate),
        formatDate(enddate),
        name.value,
        "true",
        bussUnit.value,
        room.value
      );
      console.log("Abstract: ", desktopActivity);
      if (desktopActivity.success) {
        setActivity(desktopActivity.data);
      }
      setLoading(false);
    } catch (e) {
      console.log("could not fetch data");
      setLoading(false);
    }
  };

  // console.log({ activityDetailed });
  const doSearchForUser = async (username, totalTime) => {
    setTotaltime(totalTime);
    setModalShow(true);
    setLoading(true);
    try {
      const desktopActivity = await getDesktopActivity(
        formatDate(startdate),
        formatDate(enddate),
        username,
        "false",
        "",
        ""
      );
      console.log("Detailed: ", desktopActivity);
      if (desktopActivity.success) {
        setActivityDetailed(desktopActivity.data);
      }
      setLoading(false);
    } catch (e) {
      console.log("could not fetch data");
      setLoading(false);
    }
  };

  console.log({ startdate, enddate });
  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">Desktop Activity</span>

        <div className="datepicker-container">
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
            // format="yyyy-MM-dd HH:mm:ss"
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />

          <div className="text-center mt-4">
            <Button id="search" variant="info" onClick={() => DoSearch()}>
              Search
            </Button>
          </div>
        </div>
        <div className="filters-container mt-4" style={{marginLeft: "auto", width: "50%"}}>
              <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // name="username"
                          value={name}
                          options={nameArray}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All User" />
                          )}
                          onChange={(event, value) => handleNameChange(value)}
                        />
                </FormControl>

                <FormControl fullWidth>
                {userRole === "Supervisor" ? (
                    // If the user is a Supervisor, display their assigned business unit as a disabled Autocomplete
                    <TextField
                      value={bussUnits?.length > 0 && (bussUnits[1]?.label || "")} // Use the Supervisor's assigned business unit
                      InputProps={{
                        readOnly: true, // Make the field read-only
                      }}
                    />
                  ) : (
                    // For other roles, show the Autocomplete dropdown
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={bussUnit}
                      options={bussUnits}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="All Business Units" />
                      )}
                      onChange={(event, value) => handleBussUnitChange(value)}
                    />
                  )}
                </FormControl>
          {/* <Dropdown className="select-item">
            <Dropdown.Toggle
              variant="success"
              id="menu-dropdown-name"
              style={{ width: "90%" }}
            >
              {name ? name : "All Users"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "400px",
                width: "90%",
                overflowY: "scroll",
              }}
            >
              {userRole !== "Director" && (
                <Dropdown.Item
                  onClick={() => {
                    setName("");
                  }}
                >
                  All
                </Dropdown.Item>
              )}
              {nameArray?.map((m) => (
                <Dropdown.Item
                  onClick={() => {
                    handleNameChange(m);
                  }}
                >
                  {m}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Dropdown className="select-item">
            <Dropdown.Toggle
              variant="success"
              id="menu-dropdown-room"
              style={{ width: "90%" }}
            >
              {bussUnit ? bussUnit : "Business Units"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                width: "90%",
              }}
            >
              {userRole !== "Director" ? (
                <Dropdown.Item
                  onClick={() => {
                    handleBussUnitChange("All");
                  }}
                >
                  All
                </Dropdown.Item>
              ) : (
                ""
              )}
              {bussUnits?.map((m) => (
                <Dropdown.Item
                  onClick={() => {
                    handleBussUnitChange(m);
                  }}
                >
                  {m}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </nav>
      <div className="container-fluid hw" id="main">
        {metricsSummary && metricsSummary.length ? (
          <div className="stats-container">
            {metricsSummary.map((val) => (
              <div>
                <h1>{val.title}</h1>
                <p>{val.value}</p>
              </div>
            ))}

           
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="report-container">
          {activity.length > 0 ? <CSVLink
          className="link"
          filename={`activity-summary-${Date.now()}`}
          data={csvData}
          onClick={(event) => {
            exportToActivityData();
          }}
        >
          Export Activity Data
        </CSVLink>
        : null}
            <div className="header">
              <h1 className="session-chart">Desktop Activity</h1>
              <div className="disp-flex-left"></div>
            </div>

            <Table striped bordered hover>
              <thead className="activity-thead">
                <tr>
                  <th>Username</th>
                  <th>Applications Used</th>
                  <th>Activity</th>
                  <th>Total Tracking Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* @ts-ignore  */}
                {activity
                  ? activity?.map((val, key) => {
                      const {
                        userId,
                        username,
                        totalProductiveTime,
                        totalNonProductiveTime,
                        totalNeutralTime,
                        allApps,
                      } = val;
                      const control = `example-collapse-text-${key}`;
                      return (
                        <>
                          <tr key={key}>
                            <td
                              className="analytics"
                              aria-controls={control}
                              aria-expanded={open}
                            >
                              {username}
                            </td>

                            <td>
                              {/* @ts-ignore */}
                              {allApps.map(
                                (app, index) =>
                                  index < 7 && (
                                    <Badge className="badge-info mr-1">
                                      {app}
                                    </Badge>
                                  )
                              )}
                              {
                                // @ts-ignore 
                                allApps && allApps.length > 7 ?
                                <>
                                ... +
                                <Badge className="badge-warning mr-1">
                                  {/* @ts-ignore */}
                                  {allApps.length - 7}
                                </Badge>
                                </>:""
                              }
                              
                            </td>

                            <td>
                              <ActivityChart
                                totalProductiveTime={totalProductiveTime}
                                totalNonProductiveTime={totalNonProductiveTime}
                                totalNeutralTime={totalNeutralTime}
                                secondsToHms={secondsToHms}
                              />
                            </td>
                            <td>
                              <p>
                                {getSumOfWholeTime(
                                  totalProductiveTime,
                                  totalNonProductiveTime,
                                  totalNeutralTime
                                )}
                              </p>
                            </td>
                            <td>
                              <Button
                                className="view-details-btn"
                                variant="info"
                                size="sm"
                                onClick={() =>
                                  doSearchForUser(
                                    userId,
                                    getSumOfWholeTime(
                                      totalProductiveTime,
                                      totalNonProductiveTime,
                                      totalNeutralTime
                                    )
                                  )
                                }
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
            <DesktopActivityModal
              setModal={modalOpen}
              setModalOpen={setModalOpen}
              totalTime={userTotalTime}
              activity={modalData}
            />
          </div>
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        activityDetailed={activityDetailed}
        startDate={startdate}
        endDate={enddate}
        totalTime={totalTime}
        desktopApps={apps}
      />
    </>
  );
};

export default DesktopActivity;
