import "./releaseNotes.css";
import Header from "./../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useEffect } from "react";
import { fetchReleaseNotesAction } from "../../actions/releaseNotes";

const ReleaseNotes = () => {
  const dispatch = useDispatch();
  const { loading, releaseNotes, error } = useSelector((state: RootState) => state.releaseNotes);

  useEffect(() => {
    dispatch(fetchReleaseNotesAction());
  }, [dispatch]);

  if (loading) {
    return <><Header /><div className="head-box-error">Loading...</div></>;
  }

  if (error) {
    return <><Header /><div className="head-box-error">No Release Notes Available Yet.</div></>;
  }

  return (
    <>
      <Header />
      <section className="release-notes">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="box-22">
                <h1 className="head-box">Release Notes</h1>
                <span className="sub-head">
                  <h4 className="sub-box-1">Date</h4>
                  <h4 className="sub-box-2">Notes (click to access details)</h4>
                </span>
                {releaseNotes.length > 0 ? (
                  releaseNotes.map((note) => (
                    <span className="sub-head">
                      <p className="sub-box-1 sub-content">
                      {new Date(note.releaseDate).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })}
                      </p>
                      <p className="sub-box-2 sub-content">
                        <a href={note.fileUrl} target="_blank" rel="noreferrer">
                          {note.fileName}
                        </a>
                      </p>
                    </span>
                  ))
                ) : (
                  <p>No release notes available.</p>
                )} 
              </div>
              <br
                style={{
                  margin: "10em",
                  display: "block",
                  fontSize: "24%",
                  content: " ",
                }}
              ></br>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReleaseNotes;