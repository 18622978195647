import {
    GET_CURRENT_USERS_SUCCESS,
    GET_CURRENT_USERS_FAILURE,
    CLEAR_CURRENTUSER_DATA
  } from "../actions/actionTypes";
  
  
  const initialState = {
    roomsession: [],
    errorMessage: "",
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const getCurrentUsersReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case GET_CURRENT_USERS_SUCCESS: {
          
        return {
          ...state,
          roomsession: action.payload.jonsParsedUsers
        };
      }
      case GET_CURRENT_USERS_FAILURE: {
        return {
          ...state,
          errorMessage: action.payload?.errorMessage,
        };
      }
      case CLEAR_CURRENTUSER_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default getCurrentUsersReducer;
  