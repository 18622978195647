import { getToken } from "../utils/Token";
import { utilRequest } from "./index";
const config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": ""
    },
  };
export const getLogo = async () => {
    config["headers"]["Authorization"] = getToken();
    const logoResponse = await utilRequest.get("/v1/api/logo/get", config);
    return logoResponse.data;
};
