import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  CLEAR_USERDETAIL_DATA,
} from "../actions/actionTypes";

const initialState = {
  custCode:"",
  custId:"",
  username: "",
  firstName: "",
  lastName: "",
  role: "",
  errorMessage: "",
  bussId:""
};
interface IAction {
  type: string;
  payload: any;
}

const modalReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        username: action.payload?.user.username,
        firstName: action.payload?.user.firstName,
        lastName: action.payload?.user.lastName,
        role: action.payload?.user.role,
        bussId: action.payload?.user.bussId,
        custCode: action.payload?.user.custCode,
        custId: action.payload?.user.custId,
        
      };
    }
    case GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload?.errorMessage,
      };
    }
    case CLEAR_USERDETAIL_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
