import "./styles.css";
import Chart from "react-google-charts";
const Piechart = (props: any) => {
  return (
    <>
      <Chart
        width={"25vw"}
        height={"250px"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={props.data}
        options={{
          is3D: true,
          legend: {
            position: "top",
          },
          slices: {
            0: { color: "red" },
            1: { color: "lawngreen" },
            2: { color: "orange" },
            3: { color: "orangered" },
            4: { color: "purple" },
            5: { color: "green" },
            6: { color: "blue" },
            7: { color: "grey" },
            8: { color: "green" },
          },
        }}
      />
    </>
  );
};

export default Piechart;
