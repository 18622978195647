import "./styles.css";
import Chart from "react-google-charts";
import { useState, useEffect } from "react";
const Gantchart = (props: any) => {
  const [screenshot, setScreenshot] = useState([] as Array<string>);
  const [data, setData] = useState([] as Array<[]>);

  const gantdata = () => {
    let data: any = [];
    let newdata: any = [];
    let screenShotVar: any = [];

    data.push([
      { type: "string", id: "Type" },
      { type: "string", id: "Name" },
      { type: "string", role: "style" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ]);
    if (props.ganttchartdata.length > 0)
      for (var i = 0; i < props.ganttchartdata.length; i++) {
        if (props.ganttchartdata[i][0].toLowerCase() === "screenshot") {
          screenShotVar = [
            ...screenShotVar,
            props.ganttchartdata[i][1].split(",")[0],
          ];
        }

        newdata.push(props.ganttchartdata[i][0]);
        // newdata.push(props.ganttchartdata[i][1]);
        newdata.push(props.ganttchartdata[i][0] === "Expression"?props.ganttchartdata[i][1]:"");
        // newdata.push("");
        newdata.push(props.ganttchartdata[i][2]);
        newdata.push(new Date(props.ganttchartdata[i][3]));
        newdata.push(new Date(props.ganttchartdata[i][4]));
        data.push(newdata);
        newdata = [];
      }

    setScreenshot(screenShotVar);
    return data;
  };

  useEffect(() => {
    let data = gantdata();
    setData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Chart
        width={"60vw"}
        height={"300px"}
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          hAxis: {
            format: "HH:mm",
          },
        }}
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 1) {
                const [selectedItem] = selection;
                const dataTable = chartWrapper.getDataTable();
                const { row } = selectedItem;
                let val = dataTable?.getValue(row, 0) as string;

                if (val.toLowerCase() === "screenshot") {
                  window.open(
                    `${process.env.REACT_APP_API_URL}/getscreenshotimage/${
                      screenshot[row - 2]
                    }`
                  );
                }
              }
            },
          },
        ]}
      />{" "}
    </>
  );
};

export default Gantchart;
