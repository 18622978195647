import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { CLOSE_MODAL } from "../../actions/actionTypes";
import { RootState } from "../../redux";
import { IModalComponent } from "./interface";
import { setLoaderForBackgroundImage } from "../../actions/backgroundImageActions";

const ModalComponent = (props: IModalComponent) => {
  const modalShow = useSelector((state: RootState) => state.modal.isOpen);
  const loading = useSelector(
    (state: RootState) => state.backgroundImage.loader
  );
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
    if(loading){
      dispatch(setLoaderForBackgroundImage());
    }
    if (props && typeof props.setNullImage === "function") {
      props.setNullImage();
    }
  };
  return (
    <Modal show={modalShow} onHide={handleModalClose} backdrop="static">
      {props.children}
    </Modal>
  );
};

export default ModalComponent;
