import { useEffect, useState } from "react";
import "./AddBulkUsers.css";
import { handleAddMultipleUsers } from "../../actions/addUserActions";
import Header from "./../../components/Header";
import Loader2 from "../../assets/images/loader2.gif";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setLoading } from "../../actions/loaderActions";

const AddUser = () => {
  const [file, setFile] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  const fileChange = (e) => {
    let fileType =  e.target.files[0].type;
    if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  || fileType === "application/vnd.ms-excel") {
      setFile(e.target.files[0]);
    } else {
      toast.error("Selected format is not supported. Please upload a valid Excel file.");
      }
  };

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploaded(false);
    dispatch(setLoading(true));
    if (file) {
      let response = await handleAddMultipleUsers(file);
      if (response.success) {
        setUploaded(true);
        setFile(undefined);
        console.log('ERRORS: ', response.validationErrors);

        setErrors(response.validationErrors);
      } else {
        toast.error(response.message);
      }
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <section className="add_user">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {!isLoading ? (
                <form className="add_form" onSubmit={handleRegister}>
                  <h1>
                    <span>Add Multiple Users</span>
                  </h1>
                  <div className="dashboardRow">
                    {/* IMPORT FROM EXCEL */}
                    <div>
                      <b>Import Excel File:</b>
                      <div>
                        <input
                          type="file"
                          className="fileSelect"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={(e) => fileChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <p>&nbsp;</p>

                  <button className="link marg-up-10" value="register">
                    <b>Create Users</b>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href={`${process.env.REACT_APP_CDN_BASE_URL}/templates/templates_TemplateUserCreate40.xlsx`} className="link marg-up-10">
                    <i>Download Template</i>
                  </a>
                </form>
              ) : (
                <img src={Loader2} alt="" />
              )}
              {/* {isLoading && <img src={Loader2} alt="" />} */}
              <br
                style={{
                  margin: "10em",
                  display: "block",
                  fontSize: "24%",
                  content: " ",
                }}
              ></br>
              {uploaded && (
                <>
                  <h2>
                    {"All users were successfully uploaded "}{" "}
                    {Object.keys(errors).length > 0 && (
                      <>except the following:</>
                    )}
                  </h2>
                  {/* console.log({errors}) */}
                  <ol>
                    {Object.keys(errors).map((rowNo, i) => (
                      <li>
                        <h6>
                          <span style={{ color: "orange" }}>
                            Row No. {rowNo}
                          </span>{" "}
                          has the following errors:{" "}
                          {/* {console.log(errors[rowNo])} */}
                          <ol>
                            {Object.keys(errors[rowNo]).map((field, j) => (
                              <li>
                                <span style={{ color: "blue" }}>{field}:</span>{" "}
                                <span className="red-alert">
                                  {errors[rowNo][field]}
                                </span>
                              </li>
                            ))}
                          </ol>
                          {/* <pre className="red-alert">
                            {JSON.stringify(errors[rowNo], null, 2)}
                          </pre> */}
                        </h6>
                      </li>
                    ))}
                  </ol>
                </>
              )}{" "}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddUser;
