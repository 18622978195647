import {reportRequest} from "./index";
import { getToken } from "../utils/Token";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": getToken()
  },
};

export const getChatData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {

  config['headers']['Authorization'] = getToken();

  const requestBody = {
    startTime: starttime,
    endTime: endtime,
    userId: rname,
    roomId: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const response = await reportRequest.post("/v1/api/chat/fetch-report", requestBody, config);
  console.log({response})
  return response.data;
};
