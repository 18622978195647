import { getToken } from "../utils/Token";
import request, { utilRequest } from "./index";


const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""
  },
};

export const getEnvironments = async () => {
  config["headers"]["Authorization"] = getToken();
  const environmentsResponse = await utilRequest.get('/v1/api/environments/get',config);
  return environmentsResponse.data;
};
