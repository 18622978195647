import { useEffect, useState } from "react";
import "./styles.css";
import Header from "./../../components/Header";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { RootState } from "../../redux";
import moment from "moment";
import { timeZoneFormat } from "../../utils/dateformat";
import { getAllGroupRoom } from "../../actions/groupRoomAction";
import AddEditGroupRoom from "./AddEditGroupRoom";
import { deleteGroupRoom } from "../../services/groupRoom";
import { toast } from "react-toastify";

createTheme("solarized", {
  text: {
    primary: "#1e3869",
    secondary: "#1e3869",
  },
  background: {
    default: "#ffffff",
  },
  context: {
    background: "#cb4b16",
    text: "#000000",
  },
  divider: {
    default: "#ada8a8",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
const GroupRoom = () => {
  const dispatch = useDispatch();

  // const [search, setSearch] = useState("");
  const [data, setData] = useState<any>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [addEditModalType, setAddEditModalType] = useState("");
  const [deleteGroupName, setDeleteGroupName] = useState("");
  const [deleteGroupId, setDeleteGroupId] = useState("");
  const [selectedGroup, setSelectedGroup] = useState({
    groupId : "",
    groupname: "",
    rooms: [],
    roomsId: [],
    bussunit:"",
    bussId : ""
  });

  let groupRoomList = useSelector(
    (state: RootState) => state.groupRoomReducer.groupRoomList
  );
  // let currentUserRole = useSelector(
  //   (state: RootState) => state.userDetails.role
  // );

  // const isLoading = useSelector(
  //   (state: RootState) => state.groupRoomReducer.loader
  // );

  const deleteIcon = (groupname: any, groupId:any) => (
    <FontAwesomeIcon
      icon={faTrash}
      onClick={() => openDeleteModal(groupname, groupId)}
    />
  );
  const editIcon = (groupId: any,groupname: any, rooms: any, roomsId: any, type: any,bussunit:string,bussId:string) => (
    <FontAwesomeIcon
      icon={faEdit}
      onClick={() => openAddEditModal(groupId, groupname, rooms, roomsId, type, bussunit, bussId)}
    />
  );

  const { format } = timeZoneFormat();

  const columns = [
    {
      name: "Sr. No",
      sortable: true,
      selector: "No",
      center: true,
    },
    {
      name: "Group Name",
      sortable: true,
      selector: "Groupname",
      center: true,
    },

    {
      name: "Business Unit",
      sortable: true,
      selector: "Bussunit",
      center: true,
    },
    {
      name: "Rooms",
      sortable: true,
      selector: "Rooms",
      center: true,
    },
    {
      name: "Creation Date",
      sortable: true,
      selector: "Creation_Date",
      center: true,
    },

    {
      name: "Delete",
      selector: "Delete",
      center: true,
    },
    {
      name: "Edit",
      selector: "Edit",
      center: true,
    },
  ];

  //  user.mfa?"Yes":"No",

  const populateUsers = (groupRooms) => {
    if (groupRooms.length > 0) {
      setData(
        groupRooms.map((groupRoom: any, index: any) =>
          getDataObject(
            index,
            groupRoom._id,
            groupRoom.groupName,
            groupRoom.roomNames,
            groupRoom.roomsId,
            groupRoom.createdAt,
            groupRoom.bussUnit ? groupRoom.bussUnit : "",
            groupRoom.bussId ? groupRoom.bussId : ""
          )
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getAllGroupRoom());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    populateUsers(groupRoomList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRoomList]);

  const getDataObject = (
    index: any,
    groupId: any,
    groupname: any,
    rooms: any,
    roomsId: any,
    createdAt: any,
    bussunit: string,
    bussId: string
  ) => {
    console.log(groupId);
    
    const abc = {
      No: index + 1,
      Groupname: groupname,
      Bussunit: bussunit,
      Rooms: rooms,
      RoomsId: roomsId,
      Creation_Date: moment(createdAt).format(format).split(" ")[0],
      Delete: deleteIcon(groupname, groupId),
      Edit: editIcon(groupId, groupname, rooms, roomsId, "edit",bussunit, bussId),
    };

    //console.log(abc);

    return abc;
  };

  const openDeleteModal = (groupname, groupId) => {
    setDeleteGroupName(groupname);
    setDeleteGroupId(groupId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteGroupName("");
    setDeleteGroupId("");
  };

  const openAddEditModal = (groupId, groupname, rooms,roomsId, type,bussunit, bussId) => {
    setAddEditModalType(type);
    setSelectedGroup({groupId, groupname, rooms, roomsId ,bussunit, bussId});
    setShowAddEditModal(true);
  };

  const closeAddEditModal = () => {
    setShowAddEditModal(false);
    setAddEditModalType("");
    setSelectedGroup({groupId: "", groupname: "", rooms: [], roomsId : [], bussunit:"", bussId: ""});
    dispatch(getAllGroupRoom());
  };

  const handleDelete = async (e: any, groupname: any, groupId : any) => {
    e.preventDefault();
    const result = await deleteGroupRoom(groupname, groupId);
    if (result.success) {
      toast(`${result.message}`);
    } else {
      toast(`${result.message} `);
      return;
    }
    deleteClearState();
    dispatch(getAllGroupRoom());
  };

  const deleteClearState = () => {
    setShowDeleteModal(false);
    setDeleteGroupName("");
    setDeleteGroupId("");
  };

  const createGroupRoom = () => {
    setAddEditModalType("create");
    setShowAddEditModal(true);
  };
  const processedData = data.map(item => ({
    ...item,
    Rooms: item?.Rooms?.join(', ')
  }));
  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">ALL GROUP ROOMS</span>

        <div className="search-user_view">
          <Button
            variant="info"
            className="add_btn_group"
            onClick={createGroupRoom}
          >
            Create Group Room
          </Button>
          {/* <Input
            type="text"
            name="username"
            placeholder="search username"
            value={search}
            onChange={(e) => handleSearch(e)}
          /> */}
          <br />
        </div>
      </nav>

      {groupRoomList.length > 0 ? (
        <DataTable
          title="GROUP ROOM"
          columns={columns}
          theme="solarized"
          noHeader
          data={processedData}
          pagination
          highlightOnHover
        />
      ) : null}
      {showAddEditModal ? (
        <Modal
          show={showAddEditModal}
          onHide={closeAddEditModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {addEditModalType === "edit" ? "Edit" : "Create"} Group Room
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditGroupRoom
              type={addEditModalType}
              editGroupData={selectedGroup}
              closeAddEditModal={closeAddEditModal}
            />
          </Modal.Body>
        </Modal>
      ) : null}

      {showDeleteModal ? (
        <Modal
          show={showDeleteModal}
          onHide={closeDeleteModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Group {deleteGroupName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <h6>Do you want to continue ?</h6>

              <button
                className="link mt-3"
                onClick={(e) => handleDelete(e, deleteGroupName, deleteGroupId)}
              >
                YES
              </button>
              <button
                className="link mt-3 margin-left-10"
                onClick={(e) => {
                  e.preventDefault();
                  deleteClearState();
                }}
              >
                NO
              </button>
            </form>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default GroupRoom;
