import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Spinner } from "react-bootstrap";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import {
  getBussUnitsAction,
  addBussUnit,
  delBussUnit,
} from "../../actions/bussUnitActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import "./AddBussUnit.css";
import { toast } from "react-toastify";

const AddBussUnit = () => {
  const dispatch = useDispatch();
  const [bussUnitCheck, setBussUnitCheck] = useState(false);
  const bussUnits = useSelector((state: RootState) => state.bussUnit.bussUnits);
  const loader = useSelector((state: RootState) => state.loading.isLoading);
  const [bussValue, setBussValue] = useState("");

  useEffect(() => {
    dispatch(getBussUnitsAction());
  }, [dispatch]);

  const addBussUnitSubmit = async () => {
    let value = bussValue.trim();
    if (value === "") {
      toast.error("Please enter a valid value");
      return;
    }

    if(value.length > 20){
      setBussUnitCheck(true);
      return;
    }

    dispatch(addBussUnit(value));
    setBussValue("");
    setBussUnitCheck(false)
  };

  const changeBussValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBussValue(e.target.value);
  };

  return (
    <>
      <Header />

      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">Business Unit</span>

        <div className="datepicker-container">
          <InputGroup className="my-3" style={{ width: "30rem" }}>
            <FormControl
              placeholder="Add business unit"
              aria-label="Add business unit"
              aria-describedby="search"
              value={bussValue}
              onChange={changeBussValue}
              style={{ marginRight: "1em" }}
            />


            <Button
              id="search"
              variant="info"
              onClick={() => addBussUnitSubmit()}
              disabled={loader}
              style={{ width: "10rem" }}
            >
              {loader ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="button_spinner"
                />
              ) : null}
              Add
            </Button>
          </InputGroup>

          <br />
          {
            bussUnitCheck &&
            <p style={{color:"red"}}>Bussunit cannot exceed 20 characters</p>
          }
        </div>
      </nav>
      <div className="container-fluid hw" id="main">
        <div className="row">
          <div className="report-container">
            <div className="header">
              <h1 className="session-chart">
                {" "}
                {bussUnits.length === 0
                  ? "There are no business units created"
                  : "Number of business units: " + bussUnits.length}
              </h1>
              <div className="disp-flex-left"></div>
            </div>
          </div>
        </div>
        {bussUnits.length > 0
          ? bussUnits.map((value, index) => (
            <React.Fragment key={value._id}>
              <div className="row all_users">
                <div className="report-container">
                  <div className="single_user_name">
                    <h5>{value.bussUnit}</h5>
                  </div>
                  {/* {value.rooms && (
                      <>
                        <div className="single_user_name">
                          <h5>{value.rooms.length}</h5>
                        </div>
                        <div className="single_user_name">
                          <h5>{value.rooms}</h5>
                        </div>
                      </>
                    )} */}
                  <div className="single_user_index">
                    <h5
                      onClick={() => dispatch(delBussUnit(value._id))}
                      className="trash_buss"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </h5>
                  </div>
                </div>
              </div>

              <span className="line"></span>
            </React.Fragment>
          ))
          : null}
      </div>
    </>
  );
};

export default AddBussUnit;
