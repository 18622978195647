import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { getUserDetails } from "../services/user";
import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
} from "../actions/actionTypes";

const setUserDetails = (userData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: userData,
    });
  };
};

const setError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_USER_DETAILS_FAILURE,
      payload: errorMessage,
    });
  };
};

export const getUserDetailsAction = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const userData = await getUserDetails();
      if (userData) {
        dispatch(setUserDetails(userData));
      } else {
        dispatch(setError("Could not fetch user details. Please try again"));
      }
      return userData;
    } catch (error: any) {
      setError(error?.message || "Could not fetch user details. Please try again");
    } finally {
      dispatch(setLoading(false));
    }
  };
};
