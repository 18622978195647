import { getToken } from "../utils/Token";
import request, { authRequest } from "./index";
import qs from "querystring";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const login = async (custCode?:string, email?: string, password?: string) => {
  const requestBody = qs.stringify({
    custCode:custCode,
    username: email,
    password: password,
  });

  const response = await authRequest.post("/v1/api/adminlogin", requestBody, config);

  return response.data;
};

export const loginActiveTime =async () => {
  config['headers']['Authorization'] = getToken();
    try {
      const res = await authRequest.get("/v1/api/user/logintime/save", config);
      return res.data;
    } catch (err: unknown | any) {
      throw new Error(`Error Saving logging time for User ${err.message}`);
    }
  }

  export const logOutActiveTime = async () => {
      config["headers"]["Authorization"] = getToken();
      try {
        const res = await authRequest.get("/v1/api/user/logouttime/save", config);
        return res.data;
      } catch (err: unknown | any) {
        throw new Error(`Error Saving logging out time for User ${err.message}`);
      }
    }
  


export const checkLogin = async () => {
  config['headers']['Authorization'] = getToken();
  const response = await authRequest.get('/v1/api/user/check-login',config)
  return response.data;
}

export const verifyOTP = async (
  username?: string, 
  OTP?: string ,
  custCode?: string
) => {
  // let requestBody = qs.stringify({
  //   username: username,    
  //   OTP,
  //   password: 'mfalogin',
  // }); 

  let requestBody = qs.stringify({
    custCode: custCode,
    username: username,
    otp: OTP

});
  const response = await authRequest.post("/v1/api/verifyotp", requestBody, config);
  return response.data;
};

export const logout = async () => {
  localStorage.removeItem("token");
  return Promise.resolve("Logged Out");
};
interface isSessionActiveResp {
  message: string;
  success: boolean;
}

export const isSessionActive = async (): Promise<isSessionActiveResp> => {
  const response = await request.get("/isSessionActive");
  return response.data;
};