import moment from "moment-timezone";
import { formats, timezoneLocaleMap } from "../../constants/dateTimeMap";

export const timeZoneFormat = () => {
  const targetTimezone = moment.tz.guess();

  // Get the current date and time in the target timezone
  // const currentTime = moment().tz(targetTimezone);

  // Adjust the locale dynamically based on the target timezone
  moment.locale(moment.tz(targetTimezone).locale());

  // Determine the format based on the target timezone and locale
  let format, code;
  code = timezoneLocaleMap[targetTimezone];
  format = getLocaleDateString(code);

  // Display or use the formatted date and time
  return { format, targetTimezone };
};

function getLocaleDateString(format) {
  return formats[format] || "DD/MM/YYYY  HH:mm:ss A";
}
