export const getToken = () => {
    if (localStorage.token) {
      return "Bearer " + localStorage.token;
    } else {
      return "";
    }
  };

  export const getActivityChannelUrl = () => {
    let hostnameAC = window.location.origin;
    if (hostnameAC.includes("http://localhost")) {
          hostnameAC = "http://localhost:8087";
        } else if (hostnameAC.includes("https://localhost")) {
          hostnameAC = "http://localhost:8087";
        } else {
          hostnameAC = localStorage.activityChannel;
        }
    return hostnameAC;
  }