import {
  GET_ALL_ADMIN_SETTINGS_SUCCESS,
  GET_ALL_ADMIN_SETTINGS_FAILURE,
  SET_UPDATE_SETTINGS_SUCCESS,
  SET_UPDATE_SETTINGS_FAILURE,
  CLEAR_VIEWADMINDETAIL_DATA,
  SET_ADMIN_SETTINGS_VALUE,
} from "../actions/actionTypes";

const initialState = {
  isViewAllGetSettings: false,
  settinglist: [],
  error: "",
};
interface IAction {
  type: string;
  payload: any;
}

const AdminSettingsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ALL_ADMIN_SETTINGS_SUCCESS: {
      return {
        ...state,
        isViewAllGetSettings: true,
        settinglist: action.payload.adminsettings,
      };
    }
    case SET_ADMIN_SETTINGS_VALUE: {
      return {
        ...state,
        settinglist:
          state.settinglist.length > 0
            ? state.settinglist.map((settingObject: any) =>
                settingObject.variable === action.payload.variable
                  ? { ...settingObject, value: action.payload.value }
                  : { ...settingObject }
              )
            : [...state.settinglist],
      };
    }
    case GET_ALL_ADMIN_SETTINGS_FAILURE: {
      return {
        ...state,
        isViewAllGetUsers: false,
        error: action.payload.error,
      };
    }
    case SET_UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        //   isViewAllGetSettings: true,
        //settinglist: action.payload.success,
        settinglist: action.payload.adminsettings,
      };
    }
    case SET_UPDATE_SETTINGS_FAILURE: {
      return {
        ...state,
        //   isViewAllGetUsers: false,
        error: action.payload.error,
      };
    }
    case CLEAR_VIEWADMINDETAIL_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default AdminSettingsReducer;
