import { useEffect, useState } from "react";
import Loader2 from "../../assets/images/loader2.gif";
import "./Report.css";
import Header from "./../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { RootState } from "../../redux";
import React from "react";
import { DateTimePicker } from "react-rainbow-components";
import D3div from "../../components/D3div";
import {
  getReportDatam,
  getExportDataActions,
} from "../../actions/getReportData";
import Pagination from "react-js-pagination";
import { handleGetReportRoom } from "../../actions/getReportRoom";
import { handleGetReportName } from "../../actions/getReportName";

import ExportModal from "./ExportModal";
import { toast } from "react-toastify";
import { reportAPI } from "../../utils/api";
import { FormControl,Autocomplete, TextField} from "@mui/material";

const Report = (props: any) => {
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());

  const [exportModal, setExportModal] = useState(false);
  const [collapse, setCollapse] = useState("show");
  const [pageno, setPageno] = useState(1);
  const [isTrueName, setIsTrueName] = useState(false);
  const [name, setName] = useState({label: "All", value: ""});
  const [isTrueRoom, setTrueRoom] = useState(false);
  const [room, setRoom] = useState({label: "All", value: ""});
  const [nameArray, setNameArray] = useState([]);
  const [roomArray, setRoomArray] = useState([]);
  const ReportData = useSelector(
    (state: RootState) => state.getReportData.ReportData.report
  );

  // const userRole = useSelector((state: RootState) => state.userDetails.role);

  const isReport = useSelector(
    (state: RootState) => state.getReportData.isReport
  );
  // const nameArray = useSelector(
  //   (state: RootState) => state.getReportName.NameData
  // );
  // const roomArray = useSelector(
  //   (state: RootState) => state.getReportRoom.RoomData
  // );

  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  const dispatch = useDispatch();

  const formatDate = (date) => {
    // let hour;
    // let min;
    // let sec;

    // var d = new Date(date),
    //   month = "" + (d.getMonth() + 1),
    //   day = "" + d.getDate(),
    //   year = d.getFullYear();
    // hour = d.getHours();
    // min = d.getMinutes();
    // sec = d.getSeconds();
    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;

    // let a = [year, month, day].join("-");
    // a = a + " " + hour + ":" + min + ":" + sec;
    // return a;

    var d = new Date(date);
    return d.toUTCString();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  function EnableF(){
    getuser();
    getrooms();
    // dispatch(handleGetReportName());
    // dispatch(handleGetReportRoom());
  }

  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      let labeledUsers:any = [];
      // eslint-disable-next-line array-callback-return
      res.data.users.map((user: any, index) => {
        if (user && user.username) {
          // @ts-ignore
          labeledUsers.push({ label: user.username, value: user._id });
        }
      });
      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });
      labeledUsers.unshift({ label: "All Users", value: "All" })
      //@ts-ignore
      setNameArray(labeledUsers);
    });
  };


  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      let labeledUsers = [{label: "All Rooms", value: "All"}];
      // eslint-disable-next-line array-callback-return
      res.data.rooms.map((roomValue: any, index) => {
        if (roomValue) {
          // @ts-ignore
          labeledUsers.push({label: roomValue.roomName, value: roomValue._id});
        }
      });
      //@ts-ignore
      
      setRoomArray(labeledUsers);
    });
  };

  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };

  const handleNameChange = (s: any) => {
    console.log("ssssssssssssssssssssss",s);
    setIsTrueName(true);
    setName(s);
    if (s.value === "All") {
      setIsTrueName(false);
      setName({label: "All", value: ""});
      // return DoSearch(false, isTrueRoom, "", room);
    }
    // return DoSearch(true, isTrueRoom, s.value, room);
  };

  const handleRoomChange = (r: any) => {
    setTrueRoom(true);
    setRoom(r);
    if (r.value === "All") {
      setTrueRoom(false);
      setRoom({label: "All", value: ""});
      // return DoSearch(isTrueName, false, name, "");
    }
    // return DoSearch(isTrueName, true, name, r.value);
  };

  const DoSearch = (
    nameCheck: boolean,
    roomCheck: boolean,
    nameData?: any,
    roomData?: any
  ) => {
    setPageno(1);
    if (nameCheck && roomCheck) {
      dispatch(
        getReportDatam(
          formatDate(startdate),
          formatDate(enddate),
          nameData.value,
          roomData.value,
          1
        )
      );
    } else if (nameCheck) {
      dispatch(
        getReportDatam(
          formatDate(startdate),
          formatDate(enddate),
          nameData.value,
          "",
          1
        )
      );
    } else if (roomCheck) {
      dispatch(
        getReportDatam(
          formatDate(startdate),
          formatDate(enddate),
          "",
          roomData.value,
          1
        )
      );
    } else {
      dispatch(
        getReportDatam(formatDate(startdate), formatDate(enddate), "", "", 1)
      );
    }
  };
  const handlePageChange = (pagenumber) => {
    setPageno(pagenumber);
    dispatch(
      getReportDatam(
        formatDate(startdate),
        formatDate(enddate),
        name.value,
        room.value,
        pagenumber
      )
    );
  };
  const collapseHandler = () => {
    if (collapse === "show") {
      setCollapse("hide");
    } else {
      setCollapse("show");
    }
  };

  useEffect(() => {
    EnableF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateData = (dataType: string) => {
    dispatch(
      getExportDataActions(
        formatDate(startdate),
        formatDate(enddate),
        name.value,
        room.value,
        dataType
      )
    );
  };

  const openModal = () => {
    if (startdate.toString() === enddate.toString()) {
      toast.error("Please ensure start time is less than end time");
      return;
    }
    setExportModal(exportModal ? false : true);
  };

  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">Reporting</span>

        <div className="datepicker-container">
          <div className="text-center mt-4 export_button">
            <Button variant="info" onClick={openModal}>
              Export Data
            </Button>
          </div>
          {/* <CSVLink data={dataForExport}>Download me</CSVLink> */}
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
            // format="yyyy-MM-dd HH:mm:ss"
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />
          <div className="text-center mt-4">
            <Button
              id="search"
              variant="info"
              onClick={() => DoSearch(isTrueName, isTrueRoom, name, room)}
            >
              Search
            </Button>
          </div>
        </div>
      </nav>
      <div className="container-fluid hw" id="main">
        <div className="row">
          <div className="report-container">
            <div className="header">
              <h1 className="session-chart">Session charts</h1>
              <div className="disp-flex-left"></div>
            </div>
            {/* {display && isReport && ( */}
            <div className="filters-container" style={{width: "65%"}}>
            <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // name="username"
                          value={name}
                          options={nameArray}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All User" />
                          )}
                          onChange={(event, value) => handleNameChange(value)}
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // name="username"
                          value={room}
                          options={roomArray}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All Rooms" />
                          )}
                          onChange={(event, value) => handleRoomChange(value)}
                        />
                      </FormControl>
              {/* <Dropdown className="select-item">
                <Dropdown.Toggle
                  variant="success"
                  id="menu-dropdown-name"
                  style={{ width: "90%" }}
                >
                  {name ? name : "All Users"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: "400px",
                    width: "90%",
                    overflowY: "scroll",
                  }}
                >
                    <Dropdown.Item
                      onClick={() => {
                        handleNameChange("All");
                      }}
                    >
                      All
                    </Dropdown.Item>
  

                  {nameArray?.map((m) => (
                    <Dropdown.Item
                      onClick={() => {
                        handleNameChange(m);
                      }}
                    >
                      {m}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}
              {/* <Dropdown className="select-item">
                <Dropdown.Toggle
                  variant="success"
                  id="menu-dropdown-room"
                  style={{ width: "90%" }}
                >
                  {room ? room : "All Rooms"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                    width: "90%",
                  }}
                >
                  
                    <Dropdown.Item
                      onClick={() => {
                        handleRoomChange("All");
                      }}
                    >
                      All
                    </Dropdown.Item>
                 
                  {roomArray?.map((m) => (
                    <Dropdown.Item
                      onClick={() => {
                        handleRoomChange(m);
                      }}
                    >
                      {m}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}

              <Button variant="info" className="collps-btn" onClick={collapseHandler}>
                {collapse === "show" ? "Collapse All" : "Expand All"}
              </Button>
            </div>

            {/* <div className="alert alert-secondary text-center vertical-center" id="diss" role="alert">
					No sessions available, kindly search
				</div> */}
            <br />
            {/* <button id="collapse" >Collapse All</button> */}

            <br />

            <div id="idSessions">
              {isLoading ? (
                <img src={Loader2} alt="" />
              ) : isReport && ReportData && ReportData.length > 0 && ReportData[3][0] > 0 ? (
                ReportData[0].map((m, index) => (
                  <D3div
                    key={index}
                    piechartdata={m}
                    ganttchartdata={ReportData[1][index]}
                    headerdata={ReportData[2][index]}
                    collapse={collapse}
                  />
                ))
              ) : (
                <div className="no-chart-div">No Charts To Display</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isReport ? (
        <Pagination
          activePage={pageno}
          itemsCountPerPage={20}
          totalItemsCount={ReportData && ReportData.length > 0 && 20 * ReportData[3]}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      ) : (
        <div></div>
      )}

      <ExportModal
        show={exportModal}
        handleShow={setExportModal}
        generateData={generateData}
      />
    </>
  );
};

export default Report;
