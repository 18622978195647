import {reportRequest, deskRequest} from "./index";
import qs from "querystring";
import { getToken } from "../utils/Token";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": getToken()
  },
};

export const getDesktopActivity = async (
    starttime: any,
    endtime: any,
    username: string,
    abstractData: string,
    bussUnit: string,
    room:string
) => {
    config['headers']['Authorization'] = getToken();
    const requestBody = {
        dateFrom: starttime,
        dateTo: endtime,
        userId: username,
        abstractData,
        bussId: bussUnit,
        roomId: room
    };
    const response = await reportRequest.post("/v1/api/desktopactivity/get-desktop-activity", requestBody, config);
    return response.data;
};

export const getWebsitesActivity = async (
    starttime: any,
    endtime: any,
    username: string,
    abstractData: string,
    bussUnit: string,
    room:string
) => {
    const requestBody = qs.stringify({
        dateFrom: starttime,
        dateTo: endtime,
        name: username,
        abstractData,
        bussUnit,
        room

    });

    const response = await reportRequest.post("/getwebsitesactivity", requestBody, config);

    return response.data;
};

export const getDesktopApps = async () => {
    config['headers']['Authorization'] = getToken();
    const response = await deskRequest.get("/v1/api/desktopapps/get", config);
    return response.data;
};

export const getSignedUrl =async (url:any) => {
    const requestBody = {
        url: url
    };
    const response = await deskRequest.post("/v1/api/desktopapps/get-signed-url",requestBody, config);
    return response.data;
}

export const updateApplications = async (
    apps: any
) => {
    const requestBody = JSON.stringify(apps);
    const response = await deskRequest.post("/v1/api/desktopapps/update", requestBody, config);

    return response.data;
};

export const updateWebsites = async (
    apps: any
) => {
    const requestBody = JSON.stringify(apps);
    const response = await deskRequest.post("/v1/api/desktopapps/update-websites", requestBody, config);

    return response.data;
};