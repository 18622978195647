import {
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    CLEAR_EDITUSER_DATA
  } from "../actions/actionTypes";
  
  const initialState = {
    isUserUpdated: false,
    error: "",
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const editUserReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case UPDATE_USER_SUCCESS: {
        return {
          ...state,
          isUserUpdated: true,
        };
      }
      case UPDATE_USER_FAILURE: {
        return {
          ...state,
          isUserUpdated: false,
          error: action.payload.error,
        };
      }
      case CLEAR_EDITUSER_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default editUserReducer;
  