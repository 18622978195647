import { Dispatch } from "react";
import {
    FETCH_RELEASE_NOTES_REQUEST,
    FETCH_RELEASE_NOTES_SUCCESS,
    FETCH_RELEASE_NOTES_FAILURE,
} from "./actionTypes";
import { getReleaseNotes } from "../services/getAllReleaseNotes";

export const fetchReleaseNotesAction = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({ type: FETCH_RELEASE_NOTES_REQUEST });
        try {
            const releaseNotes = await getReleaseNotes();
            dispatch(fetchReleaseNotesSuccess(releaseNotes));
        } catch (error: any) {
            dispatch(fetchReleaseNotesFailure(error?.message || "Could not fetch release notes."));
        }
    };
};

const fetchReleaseNotesSuccess = (releaseNotes: any) => {
    return {
        type: FETCH_RELEASE_NOTES_SUCCESS,
        payload: releaseNotes,
    };
};

const fetchReleaseNotesFailure = (errorMessage: string) => {
    return {
        type: FETCH_RELEASE_NOTES_FAILURE,
        error: errorMessage,
    };
};