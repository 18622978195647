import { getToken } from "../utils/Token";
import { authRequest } from "./index";

export const getUserDetail = async (userId?: string) => {
  const config = {
    headers: {
      "Authorization": getToken(),
    },
    params: {
      userId: userId
    }
  };

  try {
    const userDetailsResponse = await authRequest.get("/v1/api/user/get", config);
    return userDetailsResponse.data;
  } catch (error) {
    console.error("Error while fetching user details:", error);
    throw error;
  }
};