import { useEffect, useRef, useState } from "react";
import "./styles.css";

const SearchDropdown = ({
  options,
  id,
  selectedVal,
  handleChange,
  multiSelect,
  handleSelect,
  multiValue,
}) => {

  console.log(options);
  
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option: string) => {
    setQuery(() => "");
    if (multiSelect) {
      handleSelect(option);
    } else {
      handleChange(option);
    }
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) => ({roomName : option.roomName?.toLowerCase().indexOf(query.toLowerCase()) > -1, id : option.id})
    );
  };

  console.log(filter(options));
  

  return (
    <div className="search_dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            value={getDisplayValue()}
            type="text"
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
            autoComplete="off"
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option.id)}
              className={`option ${multiValue.has(option.id) ? "selected" : ""}`}
              key={`${id}-${index}`}
            >
              {option.roomName}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchDropdown;
