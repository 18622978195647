import React from "react";
import "./Login.css";
import Logo from "../../assets/images/logo3.jpg";
import Input from "./../../components/Input";
import ModalComponent from "./../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { handleLogin,  handleOTP } from "../../actions/loginActions";
import { RootState } from "../../redux";
import { Alert } from "react-bootstrap";
import Loader from "./../../components/Loader";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { getLogo } from "../../services/getLogo";

const Login = () => {
  const dispatch = useDispatch();
  const loginError = useSelector((state: RootState) => state.login.error);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  // const isOTPVerified = useSelector((state: RootState) => state.login.isOTPtest);
  const isMFAEnable = useSelector((state: RootState) => state.login.isMFAEnable);
  const history = useHistory();
  const [OTP, setOTP] = React.useState("");
  const [logo, setLogo] = React.useState(Logo);
  const [,setLoginusername] = React.useState("");

  React.useEffect(() => {
    try {
      dispatch(handleLogin());
    } catch (e) {
      return;
    }

    const isTokenPresent = localStorage.getItem("token");
    if (isLoggedIn && isTokenPresent) {
      history.push("/dashboard");
    }
  }, [isLoggedIn, history, dispatch]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleCustCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustCode(e.target.value);
  };

  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOTP(e.target.value);
  };

  const handleLoginUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginusername(e.target.value);
  };

  const handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(handleLogin(custCode,email, password));
  };

  const handleOTPSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(handleOTP(email,OTP,custCode));
  };

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [custCode, setCustCode] = React.useState("");

  // const openForgetPasswordModal = () => {
  //   dispatch({
  //     type: OPEN_MODAL,
  //   });
  // };

 const fetchLogo = async () => {
  let logoResponse = await getLogo();
  if (logoResponse) {
    setLogo(logoResponse.logoUrl);
    localStorage.setItem("logo", logoResponse.logoUrl);
    let link = document.querySelector("link[rel~='apple-touch-icon']");
    // @ts-ignore
    link.href = logoResponse.logoUrl;
  }
 }

 React.useEffect(() => {
  fetchLogo();
}, []);


  if(isMFAEnable==="true" || isMFAEnable===true) {
    return (
      <>
        <div className="login-page">
          <div className="bg-box"></div>
          <div className="form">
            <img src={logo} alt="Training Room Logo" />
            <h4>Login</h4>
            <p>Enter your OTP to login our dashboard.</p>
            {loginError && <Alert variant="danger">{loginError}</Alert>}
            <form onSubmit={handleOTPSubmit}>
              <h6>OTP</h6>
              <Input
                type="text"
                name="otp-field"
                placeholder="Please enter Your OTP"
                value={OTP}
                onChange={handleOTPChange}
              />
              <Input
                type="hidden"
                name="hiddenusername-field"
                placeholder="Loginusername"
                value={email}
                onChange={handleLoginUserChange}
              />

              <button className="link" type="submit" title="Sign In">
                Verify OTP
              </button>
            </form>
            {/* commented out by Sameer Maini till we add forgot password functionality
            <button onClick={openForgetPasswordModal} className="btn-link-a">
              Forget Password?
            </button>
            */}
          </div>
        </div>
        <ModalComponent>
          <Modal.Header closeButton>
            <Modal.Title>Forget Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <h6>User Id / Email Id</h6>
              <input type="text" name="" placeholder="Email Id." />
              <button className="link mt-3">Submit</button>
            </form>
          </Modal.Body>
        </ModalComponent>
      </>
      );

    }


  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="login-page">
        <div className="bg-box"></div>
        <div className="form">
          <img src={logo} alt="Training Room Logo" />
          <h4>Login</h4>
          <p>Enter your details to login our dashboard.</p>
          {loginError && <Alert variant="danger">{loginError}</Alert>}
          <form onSubmit={handleLoginSubmit}>
          <h6>Customer Code</h6>
            <Input
              // TODO: change this to type email later
              type="text"
              name="email-field"
              placeholder="Enter your Customer Code"
              value={custCode}
              onChange={handleCustCodeChange}
            />
            <h6>Username</h6>
            <Input
              // TODO: change this to type email later
              type="text"
              name="email-field"
              placeholder="Enter your Username"
              value={email}
              onChange={handleEmailChange}
            />
            <h6>Password</h6>
            <Input
              type="password"
              name="password-field"
              placeholder="Enter your password"
              value={password}
              onChange={handlePasswordChange}
            />
            <button className="link" type="submit">
              Sign In
            </button>
          </form>

          {/* <button onClick={openForgetPasswordModal} className="btn-link-a">
            Forget Password?
          </button> */}
        </div>
      </div>
      <ModalComponent>
        <Modal.Header closeButton>
          <Modal.Title>Forget Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <h6>User Id / Email Id</h6>
            <input type="text" name="" placeholder="Email Id." />
            <button className="link mt-3">Submit</button>
          </form>
        </Modal.Body>
      </ModalComponent>
    </>
  );
};

export default Login;
