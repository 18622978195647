import "./style.css";
import PlusIcon from "../../../assets/images/plus-icon.svg";
import { Fragment, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import ModalComponent from "./../../../components/Modal";
import { CLOSE_MODAL, OPEN_MODAL } from "../../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import {
  addBackgroundImage,
  deleteBackgroundImage,
} from "../../../services/backgroundImage";
import { toast } from "react-toastify";
import {
  getAllBackgroundImageActions,
  setDeleteBackgroundImgName,
  setLoaderForBackgroundImage,
} from "../../../actions/backgroundImageActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const AddBackgroundCard = () => {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.getUserDetailData.role);
  const environments = useSelector((state: RootState) => state.environmentsReducers.environments);
  const REACT_APP_COMPANY_NAME = environments && environments.REACT_APP_COMPANY_NAME !== undefined ? environments.REACT_APP_COMPANY_NAME.value : "";

  const loading = useSelector(
    (state: RootState) => state.backgroundImage.loader
  );
  const deleteImageFileName = useSelector(
    (state: RootState) => state.backgroundImage.deleteImageFileName
  );
  const backgroundImages = useSelector(
    (state: RootState) => state.backgroundImage.allBackgroundImage
  );

  const [isWarning, setIsWarning] = useState("");

  const openAddBackgroundModal = () => {
    dispatch({
      type: OPEN_MODAL,
    });
  };

  const closeAddBackgroundModal = () => {
    setInfo(true);
    setIsWarning("");
    dispatch({
      type: CLOSE_MODAL,
    });

   
  };

  const [info, setInfo] = useState(false);
  const [imageName, setImageName] = useState(null);

  useEffect(() => {
    getAllBackgroundImageActions(process.env.REACT_APP_COMPANY_NAME as string);
  }, []);

  useEffect(() => {
    console.log({loading})
    if(loading){
      dispatch(setLoaderForBackgroundImage());
    }
  }, [])
  

  const handleAddBackgroundImage = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    dispatch(setLoaderForBackgroundImage());
    if (loading) return;
    if (imageName === null) {
      setInfo(true);
      dispatch(setLoaderForBackgroundImage());
      toast("Please select an image file.");
    } else {
      setInfo(false);
      let data = await addBackgroundImage(
        imageName,
        REACT_APP_COMPANY_NAME as string
      );
  
      if (data.success) {
        dispatch(
          getAllBackgroundImageActions(
            REACT_APP_COMPANY_NAME as string
          )
        );
        setImageName(null)
      } else {
        toast(data.message.message);
      }
      closeAddBackgroundModal();
      dispatch(setLoaderForBackgroundImage());
    }
  };

  const deleteImage = async (filename: string,fileId:string) => {
    if (deleteImageFileName === filename) return;
    dispatch(setDeleteBackgroundImgName(filename));
    const data = await deleteBackgroundImage(
      fileId,
      filename,
      REACT_APP_COMPANY_NAME as string
    );

    if (data.success) {
      toast(data.message);
      dispatch(
        getAllBackgroundImageActions(
          REACT_APP_COMPANY_NAME as string
        )
      );
    } else {
      toast(data.message);
    }

    dispatch(setDeleteBackgroundImgName(""));
  };

  const handlePictureUrlChange = (e: any) => {
    let imageFile = e.target.files[0];
    const fileSizeKB = imageFile.size / 1024;
    
    if (fileSizeKB < 100 || fileSizeKB > 5000) {
      toast.error(`The file size should be between 100KB and 5MB. Selected file size is ${(fileSizeKB / 1024).toFixed(2)}MB.`);
      setImageName(null);
      console.log(imageName);
      console.log("File size out of range, image name cleared");
      return;
    }else if (imageFile.type === "image/jpeg" || imageFile.type === "image/png") {
      setInfo(false);
      setImageName(imageFile);
    } else {
      setIsWarning("Image must be jpg or png");
      setInfo(true);
      setImageName(null);
    }
    console.log(e.target.files[0]);
  };

  const setNullImage=()=>{
    setImageName(null)
  }

  if (role === "Supervisor" || "Admin") {
    return (
      <Fragment>
        {backgroundImages && backgroundImages.length
          ? backgroundImages.map((value: any) => (
              <li key={value._id} className="back_image_li">
                <div
                  className="box add"
                  style={{ padding: "2px", width: "100%" }}
                >
                  <img
                    src={value.files.spacesUrl}
                    alt={value.files.filename}
                    style={{
                      width: "90%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    className="img_background"
                  />
                </div>
                <div
                  className="delete_back_div"
                  title="Delete"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteImage(value.files.filename,value._id);
                  }}
                >
                  {deleteImageFileName === value.files.filename ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="delete_back_icon"
                    />
                  )}
                </div>
              </li>
            ))
          : null}
        <li>
          <div
            className="box add cursor-pointer"
            onClick={openAddBackgroundModal}
          >
            <div className="center">
              <img src={PlusIcon} alt="plus" />
              <h4>Add Background Image</h4>
            </div>
          </div>
        </li>
        <ModalComponent setNullImage={setNullImage}>
          <Modal.Header closeButton>
            <Modal.Title>Add Background Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddBackgroundImage}>
              <div className="input-container">
                <h6>Image</h6>
                <p>The file size should be between 100KB and 5MB.</p>
                <input
                  type="file"
                  name="picture-Url"
                  placeholder=""
                  onChange={handlePictureUrlChange}
                />
                {info && <span className="error-msg">{isWarning}</span>}
              </div>
              <div className="submit-btn">
                <button className="link mt-3" type="submit" disabled={loading}>
                  {loading ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : null}
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </ModalComponent>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default AddBackgroundCard;
