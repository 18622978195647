import { useEffect } from "react";
import "./Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import Header from "./../../components/Header";
import { handleGetCurrentUsers } from "../../actions/getCurrentUsers";
import { getEnvironmentsAction } from "../../actions/environmentActions";
import useInterval from "../../hooks/useInterval";
import Loader from "./../../components/Loader";
import { timeZoneFormat } from "../../utils/dateformat";
import { loginActiveTime } from "../../services/login";

const Dashboard = () => {
  const dispatch = useDispatch();
  const loggedInUsers = useSelector(
    (state: RootState) => state.getCurrentUsers.roomsession
  );



useEffect(() => {
loginActiveTime()
}, [])



  const userDetails = useSelector((state: RootState) => state.userDetails);
  console.log({ loggedInUsers, userDetails });
  let pollingInterval:any;
  if (process.env.REACT_APP_POLLING_INTERVAL_IN_SEC) {
    pollingInterval =
      parseInt(process.env.REACT_APP_POLLING_INTERVAL_IN_SEC) * 1000;
  } else {
    pollingInterval = 5000;
  }


  useEffect(() => {
    dispatch(handleGetCurrentUsers());
    dispatch(getEnvironmentsAction());
    console.log(timeZoneFormat())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useInterval(() => {
    dispatch(handleGetCurrentUsers());
  }, pollingInterval);
  return (
    <>
      <Header />
      <div className="team_con">
        {loggedInUsers?.length > 0 && (
          <div className="flex-div">
            {loggedInUsers.map((o, index) => (
              <div key={index} className="card-div">
                <ul className="ullist">
                  <li>
                    <p className="bold1">Name:</p> {o.firstName} {o.lastName}
                  </li>
                  <li>
                    <p className="bold1">Role:</p> {o.role}
                  </li>
                  {/* <li>
                    <p className="bold1">Logged Date:</p>{" "}
                    {new Date(o.loggedDate).toString()}
                  </li> */}
                  <li>
                    <p className="bold1">Rooms:</p>
                    {o.room.toString()}
                  </li>
                </ul>
              </div>
            ))}

          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
/* <li>
                    <p className="bold1">Logged Date:</p>{" "}
                    {new Date(o.loggedDate).toString()}
                  </li> */
