import { getToken } from "../utils/Token";
import { authRequest } from "./index";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
  data : ""
};

export const deleteUser = async (email?: string, userId?: string) => {
  const body = {
    userId
  };
  config["headers"]["Authorization"] = getToken();
  config.data = JSON.stringify(body);
  const response = await authRequest.delete(
    "/v1/api/user/delete",
    config
  );

  return response.data;
};
