import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import "react-toastify/dist/ReactToastify.css";
import AddUser from "../Pages/AddUser";
import AddBulkUsers from "../Pages/AddBulkUsers";
import ViewAllUsers from "../Pages/ViewAllUsers";
import TwilioSetting from "../Pages/TwilioSetting";
import AdminSettings from "../Pages/AdminSettings";
import EditUser from "../Pages/EditUser";
import Report from "../Pages/Report";
import TabularReport from "../Pages/TabularReport";
import Login from "../Pages/Login";
// import Cookie from "js-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import AddRoom from "../Pages/AddRoom/AddRoom";
import License from "../Pages/LIcense";
import AddBussUnit from "../Pages/addBussUnit/AddBussUnit";
import { handleLogin } from "../actions/loginActions";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "../components/privateroute/PrivateRoute";
import RoomReports from "../Pages/RoomReports";
import { RootState } from "../redux";
import BackgroundImage from "../Pages/BackgroundImage/BackgroundImage";
import FeedbackImages from "../Pages/FeedbackImages/FeedbackImages";
import PrivateCallReport from "../Pages/PrivateCallReport/PrivateCallReport";
import UserGeoLocationReport from "../Pages/UserGeoLocationReport";
import Chat from "../Pages/chat";
import AddLogo from "../Pages/AddLogo";
import DesktopActivity from "../Pages/DesktopActivity";
import Webrtcstats from "../Pages/WebrtcStats";
import Topics from "../Pages/ActivityChannel/Topics";
import Feeds from "../Pages/Feeds";
import ClassifyApps from "../Pages/ClassifyApps/ClassifyApps";
import ClassifyWebsites from "../Pages/ClassifyWebsites/ClassifyWebsites";
import WebsitesActivity from "../Pages/WebsitesActivity";
import GroupRoom from "../Pages/GroupRoom";
import ReleaseNotes from "../Pages/ReleaseNotes";

function App() {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.userDetails.role);

  // const [role, setRole] = useState<string | null>("");
  // useEffect(() => {
  //   if (role === '' || role === null) {

  //     setRole(localStorage.getItem('role'))
  //   }
  // }, [userRole])
  React.useEffect(() => {
    dispatch(handleLogin());
  }, [dispatch]);

  // console.log({});
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/report" component={Report} />
      <PrivateRoute exact path="/desktopactivity" component={DesktopActivity} />
      <PrivateRoute
        exact
        path="/websitesactivity"
        component={WebsitesActivity}
      />
      <PrivateRoute exact path="/classifyapps" component={ClassifyApps} />
      <PrivateRoute
        exact
        path="/classifywebsites"
        component={ClassifyWebsites}
      />
      <PrivateRoute exact path="/tabular_report" component={TabularReport} />
      <PrivateRoute exact path="/background" component={BackgroundImage} />
      <PrivateRoute exact path="/all-release-notes" component={ReleaseNotes} />

      {(role === "Manager" || role === "Supervisor") && (
        <>
          <PrivateRoute exact path="/chat" component={Chat} />
          <PrivateRoute
            exact
            path="/privatecall"
            component={PrivateCallReport}
          />
          <PrivateRoute
            exact
            path="/geolocationreport"
            component={UserGeoLocationReport}
          />
          <PrivateRoute exact path="/license" component={License} />

          <PrivateRoute
            exact
            path="/feedbackimages"
            component={FeedbackImages}
          />
        </>
      )}  {role === "Director" && (
        <>
          <PrivateRoute exact path="/add_user" component={AddUser} />
          <PrivateRoute exact path="/grouping_room" component={GroupRoom} />
          <PrivateRoute exact path="/feeds/:id" component={Feeds} />
          <PrivateRoute exact path="/add_bulk_users" component={AddBulkUsers} />
          <PrivateRoute exact path="/view_all_users" component={ViewAllUsers} />
          <PrivateRoute exact path="/activity_channel" component={Topics} />
          <PrivateRoute
            exact
            path="/edit_users/:userId"
            component={EditUser}
          />
          <PrivateRoute exact path="/add_room" component={AddRoom} />
          <PrivateRoute exact path="/chat" component={Chat} />
          <PrivateRoute exact path="/license" component={License} />
          <PrivateRoute
            exact
            path="/feedbackimages"
            component={FeedbackImages}
          />
          <PrivateRoute
            exact
            path="/geolocationreport"
            component={UserGeoLocationReport}
          />
          <PrivateRoute exact path="/roomreports" component={RoomReports} />
          <PrivateRoute
            exact
            path="/privatecall"
            component={PrivateCallReport}
          />
        </>
      )}  {role === "Admin" && (
        <>
          <PrivateRoute exact path="/add_user" component={AddUser} />
          <PrivateRoute exact path="/grouping_room" component={GroupRoom} />
          <PrivateRoute exact path="/activity_channel" component={Topics} />
          <PrivateRoute exact path="/feeds/:id" component={Feeds} />
          <PrivateRoute exact path="/add_bulk_users" component={AddBulkUsers} />
          <PrivateRoute exact path="/view_all_users" component={ViewAllUsers} />
          <PrivateRoute
            exact
            path="/edit_users/:userId"
            component={EditUser}
          />
          <PrivateRoute exact path="/add_room" component={AddRoom} />

          <PrivateRoute exact path="/add_logo" component={AddLogo} />

          <PrivateRoute
            exact
            path="/twilio_setting"
            component={TwilioSetting}
          />
          <PrivateRoute
            exact
            path="/admin_settings"
            component={AdminSettings}
          />
          <PrivateRoute exact path="/license" component={License} />
          <PrivateRoute exact path="/businessunit" component={AddBussUnit} />
          <PrivateRoute exact path="/roomreports" component={RoomReports} />
          <PrivateRoute
            exact
            path="/privatecall"
            component={PrivateCallReport}
          />

          <PrivateRoute
            exact
            path="/feedbackimages"
            component={FeedbackImages}
          />

          <PrivateRoute
            exact
            path="/geolocationreport"
            component={UserGeoLocationReport}
          />
          <PrivateRoute exact path="/chat" component={Chat} />
          <PrivateRoute exact path="/webrtcstats" component={Webrtcstats} />
        </>
      )}
      {
        (role === "" || role === null) && <Redirect to="/login" />
      }



      {/* <Route exact path="/dashboard">
        <Dashboard />
      </Route>
      <Route exact path="/add_room">
        <AddRoom />
      </Route>
      <Route exact path="/add_user">
        <AddUser />
      </Route>
      <Route exact path="/view_all_users">
        <ViewAllUsers />
      </Route>
      <Route exact path="/edit_users/:username" component={EditUser}>
        <EditUser />
      </Route>
      <Route exact path="/report">
        <Report />
      </Route>
      <Route exact path="/license">
        <license/>
      </Route>
      <Route exact path="/businessunit">
        <AddBussUnit/>
      </Route> */}

      <Redirect to="/login" />
    </Switch>
  );
}

export default App;
