import axios from "axios";
import { getActivityChannelUrl } from "../utils/Token";
import { getToken } from "../utils/Token";
import { activityChannelRequest } from ".";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
  data: "",
};

export const getFeeds = async (topicId: any) => {
  config["headers"]["Authorization"] = getToken();
  let body = {
    topicId: topicId,
  };

  // Repeative code written intentionally. Do not remove

  const feedsResponse = await activityChannelRequest.post(
    "/v1/api/feeds/get-admin-feeds",
    body,
    config
  );
  return feedsResponse.data;
};

export const deleteFeed = async (feedId: string) => {
  let body = {
    feedId: feedId,
  };

  config["headers"]["Authorization"] = getToken();
  config.data = JSON.stringify(body);
  const feedResponse = await activityChannelRequest.delete(
    "/v1/api/feeds/delete",
    config
  );
  return feedResponse.data;
};

export const editFeed = async (
  feedId: string,
  topicId: string,
  title: string,
  description: string,
  type: string
) => {
  let body = {
    _id: feedId,
    topicId,
    title,
    description,
    type,
  };

  // Repeative code written intentionally. Do not remove
  config["headers"]["Authorization"] = getToken();
  const feedResponse = await activityChannelRequest.put(
    "/v1/api/feeds/update",
    body,
    config
  );
  return feedResponse.data;
};
