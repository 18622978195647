import { getToken } from "../utils/Token";
import { utilRequest } from "./index";


export const addLogo = async (logoName) => {
    const config_img = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    config_img["headers"]["Authorization"] = getToken();
    const data = new FormData();
    data.append("file", logoName);
    const uploadResponse = await utilRequest.post("/v1/api/logo/upload", data, config_img);
    return uploadResponse.data;
};