import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  CLOSE_MODAL,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS
} from "./actionTypes";
import { deleteUser } from "../services/deleteUser";
import { handleViewAllUsers } from "./viewAllUsers";

export const handleDeleteUser = (username: string,userId:string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch(setLoading(true));
      try {
        if (username ) {
          
          const responseData = await deleteUser(username,userId);
          if (responseData.success) {
            dispatch(setSuccess());
            dispatch(handleViewAllUsers());
          } else {
            dispatch(setDeleteUserError("Incorrect Fields"));
          }
          return responseData;
        } else {
          const responseData = await deleteUser();
          if (responseData.success) {
            dispatch(setSuccess());
          }
          return responseData;
        }
      } catch (error: any) {
        if (username) {
          if (error.message && error.message.message) {
            dispatch(setDeleteUserError(error.message.message));
          } else {
            dispatch(setDeleteUserError("Incorrect Fields"));
          }
        } else {
          dispatch(setDeleteUserError(""));
        }
      } finally {
        dispatch(setLoading(false));
        dispatch({type:CLOSE_MODAL})
      }
    };
  };
  
  const setSuccess = () => {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: DELETE_USER_SUCCESS,
      });
    };
  };
  
  const setDeleteUserError = (errorMessage: string) => {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: DELETE_USER_FAILURE,
        payload: {
          error: errorMessage,
        },
      });
    };
  };
  
  
  
  